import React, { useState } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ChildKeyTableRow from './ChildKeyTableRow';
import Title from './Title';
import { data } from '../data/getKeys';
import GenerateChildKeyModal from './GenerateChildKeyModal';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  addButton:{
    whiteSpace: 'nowrap',
  },
}));
const subLimitDict = {
  Free: 0,
  'Free-100': 0,
  'Free-250': 0,
  'Bot Sheets Lite': 5,
  'Bot Sheets Pro': 10,
};

export default function ChildKeys(props) {
  const classes = useStyles();
  const childKeys = props.allKeys.filter((keyData) => keyData.isChild);
  const [generateKeyModalOpen, setGenerateKeyModalOpen] = useState(false);

  function openGenerateKeyModal(event) {
    event.preventDefault();
    setGenerateKeyModalOpen(true);
  }

  return (
    <React.Fragment>
      {props.fetchingKeys ? (
        <React.Fragment></React.Fragment>
      ) : (
        <GenerateChildKeyModal
          keyData={props.allKeys}
          allowedToGenerate={props.allowedToGenerateSubs}
          generateKeyModalOpen={generateKeyModalOpen}
          setGenerateKeyModalOpen={setGenerateKeyModalOpen}
          getAllKeys={props.getAllKeys}
        />
      )}
      {childKeys.length <= 0 ? (
        <React.Fragment></React.Fragment>
      ) : (
        <React.Fragment>
          <Title>Sub-Accounts</Title>
          <Table size="small">
            <TableHead>
              <TableRow style={{backgroundColor:'#EFEFEF'}}>
                <TableCell>Name</TableCell>
                <TableCell>Botsheets Key</TableCell>
                <TableCell align="right">Requests</TableCell>
                <TableCell>Active</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {childKeys.map((keyData) => (
                <ChildKeyTableRow
                  getAllKeys={props.getAllKeys}
                  key={keyData.key}
                  keyData={keyData}
                />
              ))}
            </TableBody>
          </Table>
          <div className={classes.seeMore}>
            <Button variant="contained" color="secondary" className={classes.addButton} href="#" onClick={openGenerateKeyModal}>Add New</Button>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
