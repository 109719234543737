import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ConfigurationForm from './ConfigurationForm';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function WebviewForms(props) {
  const classes = useStyles();
  const [configJson, setConfigJson] = React.useState([]);
  const [fetchingConfig, setFetchingConfig] = React.useState(true);

  useEffect(() => {
    props
      .getConfig({ configID: props.configID, setConfigJson, setFetchingConfig })
      .then((response) => {
        setConfigJson(response.config);
        setFetchingConfig(false);
      });
  }, [props.configID]);

  return (
    <div className={classes.root}>
      <React.Fragment>
        {fetchingConfig ? (
          <Backdrop className={classes.backdrop} open={fetchingConfig}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <ConfigurationForm
            // DO NOT SUBMIT
            //config={EXAMPLE_CONFIG}
            configJson={configJson}
            configMetadata={props.config}
            configID={props.configID}
            saveConfig={props.saveConfig}
            manychatFlows={props.manychatFlows}
          />
        )}
      </React.Fragment>
    </div>
  );
}
