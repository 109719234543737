import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import DEFAULT_CONFIG from '../Templates/default_config';
import PIZZA_CONFIG from '../Templates/pizza_config';
import SANDWICH_CONFIG from '../Templates/sandwich_config';
import REAL_ESTATE_CONFIG from '../Templates/real_estate_config';
import TSHIRT_CONFIG from '../Templates/tshirt_config';
import SHIPPING_CONFIG from '../Templates/shipping_config';
import STARTER_KIT_PIZZA from '../Templates/starter_kit_pizza';
import STARTER_KIT_REAL_ESTATE from '../Templates/starter_kit_real_estate';

//import NotAllowedModal from '../../Modals/NotAllowedModal';

export default function ConfigCreateModal(props) {
  const [templateChoice, setTemplateChoice] = React.useState('Default Pizza');

  const closeModal = () => {
    props.setOpenAddConfigModal(false);
  };
  function getTemplate() {
    switch (templateChoice) {
      case 'Default Pizza':
        return PIZZA_CONFIG;
      case 'Starter Kit Pizza':
        return STARTER_KIT_PIZZA;
      case 'Starter Kit Real Estate':
        return STARTER_KIT_REAL_ESTATE;
      case 'Real Estate Features':
        return REAL_ESTATE_CONFIG;
      case 'Sandwich':
        return SANDWICH_CONFIG;
      case 'T-shirt Options':
        return TSHIRT_CONFIG;
      case 'Shipping Countries':
        return SHIPPING_CONFIG;
      default:
        return DEFAULT_CONFIG;
    }
  }
  function handleSubmit(e) {
    e.preventDefault();
    const template = getTemplate();
    let configName = templateChoice;
    props.saveConfig(template, 'config_' + Number(Date.now()), configName).then(() => {
      props.getConfigs({
        user: props.user,
        setFetchingConfigIDs: props.setFetchingConfigIDs,
      });
      closeModal();
    });
  }

  return (
    <>
        <Dialog open={props.modalOpen} onClose={closeModal} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Create a New Configuration</DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText>Choose a template or start from scratch.</DialogContentText>

              <MatTextField
                variant="outlined"
                label="Select a Template"
                select
                templateChoice={templateChoice}
                setTemplateChoice={setTemplateChoice}
              >
                <MenuItem value={'Default Pizza'}>Pizza Options</MenuItem>
                <MenuItem value={'Starter Kit Pizza'}>Starter Kit Pizza</MenuItem>
                <MenuItem value={'Starter Kit Real Estate'}>Starter Kit Real Estate</MenuItem>
                <MenuItem value={'Sandwich'}>Sandwich Options</MenuItem>
                <MenuItem value={'Real Estate Features'}>Real Estate Features</MenuItem>
                <MenuItem value={'T-shirt Options'}>T-shirt Options</MenuItem>
                <MenuItem value={'Shipping Countries'}>Shipping Countries</MenuItem>
                {/*<MenuItem value={"Spa services"}>Spa services</MenuItem>
            <MenuItem value={"T-Shirt Builder"}>T-Shirt Builder</MenuItem>*/}
                <MenuItem value={'Start from Scratch'}>Start from Scratch</MenuItem>
              </MatTextField>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeModal} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Create
              </Button>
            </DialogActions>
          </form>
        </Dialog>
    </>
  );
}

function MatTextField({ name, label, children, templateChoice, setTemplateChoice, ...props }) {
  // NOTE: because of the way MUI is implemented, onChange doesn't
  // fire for <TextField select />.
  return (
    <TextField
      defaultValue={templateChoice}
      onChange={(e) => {
        setTemplateChoice(e.target.value);
      }}
      variant="outlined"
      label={label}
      {...props}
    >
      {children}
    </TextField>
  );
}
