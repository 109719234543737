import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { config_data } from '../data/webviewConfigs';
import Backdrop from '@material-ui/core/Backdrop';
import { CircularProgress, Paper } from '@material-ui/core';
import axios from 'axios';
import WebviewKeys from './Tokens/WebviewKeys';
import { API } from 'aws-amplify';
import { TableBody, Snackbar, TableCell, TableHead, TableRow, Table, Tooltip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ConfigurationRow from './Configurations/ConfigurationRow';
import ConfigCreateModal from './Configurations/Modals/ConfigCreateModal';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import VideoModal from './Modals/VideoModal';

async function getWebviewKeys({ user, setWebviewKeys, setFetchingWebviewKeys }) {
  const headers = {
    headers: {
      method: 'getWebviewKeys',
      sub: user.attributes.sub,
      Accept: '*/*',
      'Access-Control-Allow-Origin': 'http://localhost:3000',
      'Access-Control-Allow-Credentials': 'true',
    },
  };
  const data = {};
  return await axios
    .post('https://api.botsheets.com/dashboard/api', data, headers)
    .then((response) => {
      if (response.status == '200') {
        setWebviewKeys(response.data['webview-keys']);
        setFetchingWebviewKeys(false);
        return { success: true, response: response };
      }
    })
    .catch((response) => {
      return { success: false, message: 'Error on getting webview keys' };
    });
}

function getKeys({
  user,
  setHasBotsheetsKey,
  setUsagePlan,
  setBotsheetsKeys,
  setFetchingBotsheetsKeys,
}) {
  let apiName = 'botsheetsDashboardApi';
  let path = '/api';
  let myInit = {
    headers: {
      method: 'getKeys',
      sub: user.attributes.sub,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
    response: true, // (return the entire Axios response object instead of only response.data)
  };
  API.post(apiName, path, myInit)
    .then((response) => {
      if (response.data.success) {
        let data = response.data.keys;
        setBotsheetsKeys(data);
        const parent_keys = data.filter((keyData) => !keyData.isChild);
        if (parent_keys.length < 1) {
          setHasBotsheetsKey(false);
        } else {
          const usage_plan = parent_keys[0]['usagePlan'];
          setUsagePlan(usage_plan);
          setFetchingBotsheetsKeys(false);
        }
      } else {
        setHasBotsheetsKey(false);
        setFetchingBotsheetsKeys(false);
      }
    })
    .catch((error) => {
      console.log(error);
      setHasBotsheetsKey(false);
      setFetchingBotsheetsKeys(false);
    });
}

export default function WebviewsContent(props) {
  const [fetchingConfigIDs, setFetchingConfigIDs] = React.useState(true);
  const [webviewKeys, setWebviewKeys] = React.useState([]);
  const [manychatFlows, setManychatFlows] = React.useState([]);
  const [fetchingWebviewKeys, setFetchingWebviewKeys] = React.useState(true);
  const [fetchingBotsheetsKeys, setFetchingBotsheetsKeys] = React.useState(true);
  const [openAddConfigModal, setOpenAddConfigModal] = React.useState(false);
  const [openOldWebview, setOpenOldWebview] = React.useState(false);
  const [usagePlan, setUsagePlan] = React.useState('empty');
  const [hasBotsheetsKey, setHasBotsheetsKey] = React.useState(true);
  const [botsheetsKeys, setBotsheetsKeys] = React.useState([]);
  const [videoModalOpen, setVideoModalOpen] = React.useState(false);
  const [isRefreshSnackbarVisible, setIsRefreshSnackbarVisible] = React.useState(false);
  const [refreshSuccess, setRefreshSuccess] = React.useState(false);

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    container: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
    addButton:{
      whiteSpace: 'nowrap',
      marginTop: theme.spacing(2)
    },
    howItWorks:{
      marginBottom: theme.spacing(2),
      textAlign:'right'
    },
    refreshPermissions:{
      marginRight: theme.spacing(2)
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1
    }
  }));
  const classes = useStyles();

  useEffect(() => {
    props.getConfigs({
      user: props.user,
      setConfigs: props.setConfigs,
      setFetchingConfigIDs,
    });
    getWebviewKeys({
      user: props.user,
      setWebviewKeys,
      setFetchingWebviewKeys,
    })
    .then((getWebviewKeysResponse) => {
      getAllManychatFlows({
        webviewKeyData: getWebviewKeysResponse,
        setManychatFlows
      })
    })
    getKeys({
      user: props.user,
      setHasBotsheetsKey: setHasBotsheetsKey,
      setUsagePlan: setUsagePlan,
      setBotsheetsKeys: setBotsheetsKeys,
      setFetchingBotsheetsKeys: setFetchingBotsheetsKeys,
    });
  }, []);

  async function refreshData({user, setWebviewKeys}){
    getWebviewKeys({
      user: user,
      setWebviewKeys,
      setFetchingWebviewKeys,
    })
    .then((getWebviewKeysResponse) => {
      getAllManychatFlows({
        webviewKeyData: getWebviewKeysResponse,
        setManychatFlows
      })
    })
  }
  async function getConfig({ configID }) {
    const headers = {
      headers: {
        method: 'getConfig',
        'config-id': configID,
        Accept: '*/*',
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'Access-Control-Allow-Credentials': 'true',
      },
    };
    const data = {};

    try {
      let response = await axios.post('https://api.botsheets.com/dashboard/api', data, headers);
      if (response.status == '200') {
        let _config = response.data['config-json'];
        //return _config;
        //setFetchingConfig(false);
        return { success: true, config: _config };
      }
      throw '';
    } catch {
      return {
        success: false,
        message: 'NO configuration found for config-id: ' + configID,
      };
    }
  }
  //This function should be called once because the same manychat flows are used 
  // for every webview configuration.
  async function getAllManychatFlows({webviewKeyData, setManychatFlows}){
    var key_data = webviewKeyData['response']['data']['webview-keys']
    var mc_flows = []
    for (var i = 0; i < key_data.length; i++) {
      var mc_key = key_data[i]['mcKey']
      var key_page_data = key_data[i]['page_info']['data']
      if ( key_page_data == undefined){
        continue
      }else{
        var page_name = key_page_data['name'] 
      }
      getManychatFlows(mc_key, page_name)
      .then((response)=>{
        if (response['data']['success']){
          mc_flows.push(response['data'])
        }
      })
    }
    setManychatFlows(mc_flows)
  }
  async function getManychatFlows(mc_key, page_name) {
    const headers = {
      headers: {
        method: 'getFlows',
        'mc-key': mc_key,
        Accept: '*/*',
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'Access-Control-Allow-Credentials': 'true',
      },
    };
    const data = {};

    try {
      let response = await axios.post('https://api.botsheets.com/dashboard/api', data, headers);
      if (response.status == '200') {
        let data = response.data;
        data['manychat_page'] = page_name
        return { success: true, data: data };
      }
      throw '';
    } catch {
      return {
        success: false,
        message: 'NO Manychat flows found',
      };
    }
  }

  function doOpenAddConfigModal(event) {
    event.preventDefault();
    setOpenAddConfigModal(true);
  }

  
  function doOpenOldWebview(event) {
    event.preventDefault();
    setOpenOldWebview(true);
  }

  function openVideoModal(event) {
    event.preventDefault();
    setVideoModalOpen(true);
  }
  
  async function refreshPermissions(){
    var bsKey = botsheetsKeys.filter((keyData) => !keyData.isChild)[0].key
    let apiName = 'botsheetsDashboardApi';
    let path = '/api';
    let myInit = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      },
      body: {
        'method': "refreshPermissions",
        'bs-key': bsKey
      },
      response: true, // (return the entire Axios response object instead of only response.data)
    };
    return await API.post(apiName, path, myInit)
      .then((response) => {
        if (response.data.success) {
          setRefreshSuccess(true)
          setIsRefreshSnackbarVisible(true)
          return response
        } else {
          console.log('ERROR');
          setRefreshSuccess(false)
          setIsRefreshSnackbarVisible(true)
          return response
        }
      })
      .catch((error) => {
        setRefreshSuccess(false)
        setIsRefreshSnackbarVisible(true)
        console.log(error);
        return {'success':false}
      });
  }
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsRefreshSnackbarVisible(false);
  };

  async function saveConfig(new_config, configID, configName = 'My Config') {
    let apiName = 'botsheetsDashboardApi';
    let path = '/api';
    let myInit = {
      headers: {
        method: 'saveConfig',
        'config-id': configID,
        'config-name': configName,
        sub: props.user.attributes.sub,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      },
      body: new_config,
      response: true, // (return the entire Axios response object instead of only response.data)
    };
    return await API.post(apiName, path, myInit)
      .then((response) => {
        if (response.data.success) {
          //getConfig({ configID: props.configID});
          return response
        } else {
          console.log('ERROR');
          //getConfig({ configID: props.configID });
          return response
        }
      })
      .catch((error) => {
        console.log(error);
        //getConfig({ configID: props.configID });
        return {'success':false}
      });
  }

  return (
    <div className={classes.root}>
      <Snackbar open={isRefreshSnackbarVisible} autoHideDuration={3000} onClose={handleClose}>
        {refreshSuccess ? (
          <Alert onClose={handleClose} severity="success">
            Refresh Complete
          </Alert>
        ) : (
          <Alert onClose={handleClose} severity="error">
            Error on Refreshing
          </Alert>
        )}
      </Snackbar>
      <Container maxWidth="lg" className={classes.container}>
        {fetchingConfigIDs || fetchingWebviewKeys || fetchingBotsheetsKeys ? (
          <Backdrop
            color='primary'
            className={classes.backdrop}
            open={fetchingConfigIDs || fetchingWebviewKeys || fetchingBotsheetsKeys}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <>
            <div className={classes.howItWorks}>
              <div >
                  <Tooltip title="Try this magic button before anything else">
                    <Button className={classes.refreshPermissions} variant="contained" color="primary" onClick={refreshPermissions} >
                    Refresh Permissions
                    </Button>
                  </Tooltip>
              </div>
            </div>
            <VideoModal
              setVideoModalOpen={setVideoModalOpen}
              videoModalOpen={videoModalOpen}
            />
            <ConfigCreateModal
              usagePlan={usagePlan}
              setOpenAddConfigModal={setOpenAddConfigModal}
              saveConfig={saveConfig}
              getConfigs={props.getConfigs}
              setFetchingConfigIDs={props.setFetchingConfigIDs}
              modalOpen={openAddConfigModal}
              user={props.user}
            />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="primary">
                      Step 1: Create a Webview Token
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <WebviewKeys
                      user={props.user}
                      webviewKeys={webviewKeys}
                      fetchingWebviewKeys={fetchingWebviewKeys}
                      getWebviewKeys={getWebviewKeys}
                      refreshData={refreshData}
                      setWebviewKeys={setWebviewKeys}
                      usagePlan={usagePlan}
                      fetchingBotsheetsKeys={fetchingBotsheetsKeys}
                      hasBotsheetsKey={hasBotsheetsKey}
                      botsheetsKeys={botsheetsKeys}
                    />
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} />
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="primary">
                      Step 2: Create a Webview Configuration
                    </Typography>
                  </Grid>
                  {props.configs.length < 1 ? (
                    <div>
                      <Typography color="textSecondary">
                        A bot can have multiple Webviews.{' '}
                        <Link href="#" color="primary" onClick={doOpenAddConfigModal}>
                          Configure a Webview
                        </Link>{' '}
                        and manage data from your Google Sheet.
                      </Typography>
                      <Button variant="contained" color="primary" className={classes.addButton} href="#" onClick={doOpenAddConfigModal}>Add New</Button>
                    </div>
                  ) : (
                    <div>
                    <Table size="small" className={classes.table}>
                      <TableHead>
                        <TableRow style={{backgroundColor:'#EFEFEF'}}>
                          <TableCell>Edit</TableCell>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>Configuration Name</TableCell>
                          <TableCell align="right">Delete</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <React.Fragment>
                          {props.configs
                            .sort((a, b) => (a['config-id'] > b['config-id'] ? 1 : -1))
                            .map((config) => (
                              <ConfigurationRow
                                key= {config['config-id']}
                                config={config}
                                user={props.user}
                                getConfigs={props.getConfigs}
                                saveConfig={saveConfig}
                                getConfig={getConfig}
                                manychatFlows={manychatFlows}
                              ></ConfigurationRow>
                            ))}
                        </React.Fragment>
                      </TableBody>
                    </Table>
                    <Button variant="contained" color="primary" className={classes.addButton} href="#" onClick={doOpenAddConfigModal}>Add New</Button>
                  </div>
                  )}
                  {props.configs.length > 0 ? (
                    <Grid item xs={12}>
                      <div style={{ display: 'flex', padding: 10 }}>
                        <Typography color="textSecondary">
                          You can configure different Webviews within your bot. For example, you
                          might trigger a Webview for the user to make a required selection
                          (S,M,L,XL) or multiple selections (extra cheese, pepperoni, mushrooms). If
                          you want to have multiple webview configurations, you can
                          {' '}
                          <Link href="#" color="primary" onClick={doOpenAddConfigModal}>
                            add another configuration.
                          </Link>
                        </Typography>
                      </div>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} />
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="primary">
                      Step 3: Add the Webview to your bot
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="textSecondary">
                    To learn more about how to create Webviews and trigger them in your chatbot,                    {` `}
                      <Link
                        href="https://www.botsheets.com/manychat/webviews.html"
                        target="_blank"
                        color="primary"
                      >
                      click here. 
                      </Link>
                    </Typography>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  fixedHeight: {
    height: 240,
  },
}));
