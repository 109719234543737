export default {
		"submit": {
			"button": {
				"subtotal_display": "button",
				"caption": "Save",
				"target": "content20200807195143_657610"
			},
			"page": {
				"title": "Thank you",
				"url": "https://botsheets.com"
			}
		},
		"collections": [{
			"select_max": "999",
			"select_default": 0,
			"columns": [{
				"column_name": "Interior Features",
				"join_type": "delimiter",
				"type": "string",
				"mc_field": "Interior Features",
				"join_with": ", "
			}],
			"display": {
				"title": {
					"padding": "3",
					"size": "4",
					"color": "blue",
					"align": "center"
				},
				"columns": {
					"padding": "3",
					"size": "4",
					"color": "blue",
					"align": "center"
				}
			},
			"select_type": "checkbox",
			"title": "Interior Features \ud83d\udeaa",
			"select_min": 0
		}, {
			"select_max": 999,
			"select_type": "checkbox",
			"title": "Exterior Features \u2600\ufe0f",
			"select_min": 0,
			"columns": [{
				"column_name": "Exterior Features",
				"join_type": "delimiter",
				"type": "string",
				"mc_field": "Exterior Features",
				"join_with": ", "
			}]
		}, {
			"select_max": 999,
			"select_type": "checkbox",
			"title": "General Features \ud83c\udf1f",
			"select_min": 0,
			"columns": [{
				"column_name": "General Features",
				"join_type": "delimiter",
				"type": "string",
				"mc_field": "General Features",
				"join_with": ", "
			}]
		}],
		"currency": "USD",
		"theme": {
			"color": {
				"main": "#0078FF"
			}
		},
		"type": "generic",
		"title": "My Preferences",
		"version": "2"
	}