import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { Field } from 'formik';
import ColorPicker, { isValidHexColor } from './ColorPicker';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    position: 'relative',
  },
  colorPicker: {
    position: 'absolute',
    top: 56,
    padding: 8,
    background: 'white',
    borderRadius: 4,
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.48), 0 2px 4px rgba(0, 0, 0, 0.27)',
    zIndex: 1000,
  },
  selectedColor: {
    borderRadius: 12,
    position: 'absolute',
    right: 16,
    top: 16,
    height: 24,
    width: 24,
    zIndex: 1000,
    pointerEvents: 'none',
  },
});

export default function MatColorField({ name, label, ...props }) {
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const css = useStyles();
  return (
    <Field validateOnBlur name={name}>
      {({ field, meta, form: { setFieldValue, validateForm } }) => (
        <div className={css.root}>
          <TextField
            autoComplete='off'
            {...field}
            onChange={(...e) => {
              if (props.select) {
                setTimeout(validateForm);
              }
              field.onChange(...e);
            }}
            onFocus={(...e) => {
              setColorPickerVisible(true);
            }}
            onBlur={(...e) => {
              setColorPickerVisible(false);
              field.onBlur(...e);
            }}
            variant="outlined"
            error={Boolean(meta.error && meta.touched)}
            label={label}
            helperText={meta.error && meta.touched && meta.error}
            {...props}
          />
          {colorPickerVisible ? (
            <div className={css.colorPicker}>
              <ColorPicker
                color={isValidHexColor(field.value) ? field.value : '#f00'}
                onChange={(color) => setFieldValue(name, color)}
              ></ColorPicker>
            </div>
          ) : (
            ''
          )}
          <div className={css.selectedColor} style={{ background: field.value }} />
        </div>
      )}
    </Field>
  );
}
