import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Backdrop from '@material-ui/core/Backdrop';
import { CircularProgress } from '@material-ui/core';

export default function WebviewKeys(props) {
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  function preventDefault(event) {
    event.preventDefault();
  }

  const handleDeleteModalOpen = (event) => {
    event.preventDefault();
    setDeleteModalOpen(true);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    seeMore: {
      marginTop: theme.spacing(3),
    },
  }));
  const classes = useStyles();

  return (
    <React.Fragment>
      {props.fetchingWebviewKeys ? (
        <React.Fragment></React.Fragment>
      ) : (
        <React.Fragment>
          <DeleteWebviewKeyModal
            user={props.user}
            deleteModalOpen={deleteModalOpen}
            setDeleteModalOpen={setDeleteModalOpen}
            webviewKey={props.webviewKey.webviewKey}
            getWebviewKeys={props.getWebviewKeys}
            setWebviewKeys={props.setWebviewKeys}
            refreshData={props.refreshData}
          />
        </React.Fragment>
      )}

      <TableRow>
        {/*key={props.webviewKey.webviewKey} >*/}
        {props.webviewKey.page_info.status != 'error' ? (
          <TableCell>
            <Typography>{props.webviewKey.page_info.data.name}</Typography>
          </TableCell>
        ) : (
          <TableCell>
            <Typography>EXPIRED ManyChat KEY! Delete this key and create a new one.</Typography>
          </TableCell>
        )}
        <TableCell>
          {props.webviewKey.webviewKey}
          <Button
            style={{ 'paddingLeft': 0, 'paddingRight': 0 }}
            color="primary"
            href="#"
            onClick={preventDefault}
          >
            <FileCopyIcon
              fontSize="inherit"
              onClick={() => {
                navigator.clipboard.writeText(props.webviewKey.webviewKey);
              }}
            />
          </Button>
        </TableCell>
        <TableCell align="right">
          <IconButton href="#" onClick={handleDeleteModalOpen}>
            <DeleteForeverOutlinedIcon color="primary"  />
          </IconButton>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DeleteWebviewKeyModal(props) {
  const [deletingToken, setDeletingToken] = React.useState(false);

  const handleClose = () => {
    props.setDeleteModalOpen(false);
    setDeletingToken(false)
  };

  const useStyles = makeStyles((theme) => ({
  }));
  const classes = useStyles();
  
  async function deleteWebviewKey() {
    setDeletingToken(true)
    console.log('DELETE: ' + props.webviewKey);
    const headers = {
      headers: {
        method: 'deleteWebviewKey',
        'webview-key': props.webviewKey,
        sub: props.user.attributes.sub,
        Accept: '*/*',
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'Access-Control-Allow-Credentials': 'true',
      },
    };
    const data = {};
    await axios
      .post('https://api.botsheets.com/dashboard/api', data, headers)
      .then((response) => {
        if (response.status == '200') {
          console.log(response);
          handleClose();
          // This will get webview keys and manychat flows
          props.refreshData({
            user: props.user,
            setWebviewKeys: props.setWebviewKeys})
          return { success: true };
        }
      })
      .catch((response) => {
        return { success: false, message: 'Error on deleting webview keys' };
      });
  }

  return (
    <React.Fragment>
      {deletingToken ? 
          <Backdrop
            className={classes.backdrop}
            open={deletingToken}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      :
      <React.Fragment/>
      }
      <Dialog
        open={props.deleteModalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {'Are you sure you want to delete this Webview Token?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText color="primary" id={props.webviewKey}>
            {props.webviewKey}
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            WARNING: This Webview Token will be permanently deleted. Any bot using this Webview
            Token will stop communicating with Botsheets.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteWebviewKey} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
