import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import CreateWebviewKeyModal from './Modals/CreateWebviewKeyModal';
import NoBotsheetsKeyModal from './Modals/NoBotsheetsKeyModal';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import WebviewKeyRow from './WebviewKeyRow';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

export default function WebviewKeys(props) {
  const [createWebviewKeyModalOpen, setCreateWebviewKeyModalOpen] = React.useState(false);

  useEffect(() => {}, []);

  function openGenerateKeyModal(event) {
    event.preventDefault();
    setCreateWebviewKeyModalOpen(true);
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    seeMore: {
      marginBottom: theme.spacing(3),
    },
    addButton:{
      whiteSpace: 'nowrap',
      marginTop: theme.spacing(2)
    },
  }));
  const classes = useStyles();

  return (
    <React.Fragment>
      {props.fetchingWebviewKeys || props.fetchingBotsheetsKeys ? (
        <React.Fragment></React.Fragment>
      ) : (
        <React.Fragment>
          {props.hasBotsheetsKey ? (
            <CreateWebviewKeyModal
              user={props.user}
              generateKeyModalOpen={createWebviewKeyModalOpen}
              setGenerateKeyModalOpen={setCreateWebviewKeyModalOpen}
              getWebviewKeys={props.getWebviewKeys}
              setWebviewKeys={props.setWebviewKeys}
              refreshData={props.refreshData}
              usagePlan={props.usagePlan}
              botsheetsKeys={props.botsheetsKeys}
            />
          ) : (
            <NoBotsheetsKeyModal user={props.user} modalOpen={true} />
          )}
        </React.Fragment>
      )}
      {props.webviewKeys.length < 1 ? (
        <div>
          <div style={{ display: 'flex' }}>
            <Typography color="textSecondary">
              For each chatbot, create a unique{' '}
              <Link href="#" color="primary" onClick={openGenerateKeyModal}>
                Webview Token.
              </Link>
            </Typography>
          </div>
          <Button variant="contained" color="primary" className={classes.addButton} href="#" onClick={openGenerateKeyModal}>Add New</Button>
        </div>
      ) : (
        <>
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow style={{backgroundColor:'#EFEFEF'}}>
                <TableCell>Page</TableCell>
                <TableCell>Webview Token</TableCell>
                <TableCell align="right">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.webviewKeys.map((webviewKey) => (
                <WebviewKeyRow
                  key={webviewKey.webviewKey}
                  user={props.user}
                  webviewKey={webviewKey}
                  fetchingWebviewKeys={props.fetchingWebviewKeys}
                  getWebviewKeys={props.getWebviewKeys}
                  setWebviewKeys={props.setWebviewKeys}
                  refreshData={props.refreshData}
                />
              ))}
            </TableBody>
          </Table>
          <div>
            <Button variant="contained" color="primary" className={classes.addButton} href="#" onClick={openGenerateKeyModal}>Add New</Button>
          </div> 
          <div style={{ display: 'flex', padding: 10 }}>
            <Typography color="textSecondary">
              If you have multiple bots, you can
              {' '}
              <Link href="#" color="primary" onClick={openGenerateKeyModal}>
                add another Webview Token.
              </Link>
            </Typography>
          </div>
        </>
      )}
    </React.Fragment>
  );
}
