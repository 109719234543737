export default {
  version: '2',
  type: 'generic',
  title: 'Please Make a Selection',
  currency: 'USD',
  theme: {
    color: {
      main: '#0078FF',
    },
  },
  collections: [
    {
      title: 'Example Selection',
      display: {
        columns: {
          size: '4',
          color: 'blue',
          padding: '3',
          align: 'center',
        },
        title: {
          size: '4',
          color: 'blue',
          padding: '3',
          align: 'center',
        },
      },
      select_type: 'checkbox',
      select_min: 0,
      select_max: 5,
      select_default: 0,
      columns: [
        {
          column_name: 'items',
          mc_field: 'items',
          join_type: 'delimiter',
          join_with: ', ',
          type: 'string',
        },
      ],
    },
  ],
  submit: {
    button: {
      caption: 'Submit',
      target: 'content20181028224419_932635',
    },
    page: {
      title: 'Thank you',
      url: 'https://botsheets.com',
    },
  },
};

/*
,
        {
          column_name: 'price',
          mc_field: 'price',
          join_type: 'add',
          type: 'price',
        },
*/
