import React, { Component } from 'react';
import './App.css';
import Dashboard from './Dashboard/Dashboard';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Amplify, { Auth, Hub, API } from 'aws-amplify';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import WebviewsHome from './WebviewBuilder/WebviewsHome';
import SignInScreen from './SignInScreen';
import QueryBuilder from './QueryBuilder/QueryBuilder';
import Toolkits from './Toolkits/Toolkits';
import Billing from './Billing/Billing';
import RewardfulDev from './RewardfulDev/RewardfulDev';
import LogsMain from './Logs/LogsMain';
import LoadingScreen from './primitives/LoadingScreen'

/*const oauth = {
  domain: 'botsheets-dashboard-botsheets.auth.us-east-1.amazoncognito.com',
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: 'http://localhost:3000/',
  redirectSignOut: 'http://localhost:3000/',
  responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
};*/

Amplify.configure({
  Auth: {
    identityPoolId: 'us-east-1:92129705-dec5-4135-ba35-17e2bc507cdf',
    region: 'us-east-1',
    //userPoolId:'us-east-1_APQKWRpcY',
    //userPoolWebClientId:'107101721677-3l44c43e0uguk5mtdoihd3kn31eh7q4q'
  },
  API: {
    endpoints: [
      {
        name: 'botsheetsDashboardApi',
        endpoint: 'https://api.botsheets.com/dashboard',
      },
      {
        name: 'botsheetsUtilityApi',
        endpoint: 'https://api.botsheets.com/botsheets',
      },
    ],
  },
});
//Auth.configure({oauth})

//Themes here: https://v3.material-ui.com/customization/themes/#palette
const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#00a058',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#EFEFEF',
    },
  },
});


class App extends Component {
  constructor(props) {
    super(props);
    this.signOut = this.signOut.bind(this);
    // let the Hub module listen on Auth events
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          Auth.currentAuthenticatedUser()
            .then((user) => {
              console.log(user);
              this.setState({
                authState: 'signedIn',
                authData: data.payload.data,
                user: user,
              });
              this.getAllKeys();
            })
            .catch((e) => {
              console.log(e);
              this.setState({ authState: 'signIn' });
            });
          break;
        case 'signIn_failure':
          this.setState({
            authState: 'signIn',
            authData: null,
            authError: data.payload.data,
          });
          break;
        default:
          break;
      }
    });
    this.state = {
      authState: 'loading',
      user: null,
      authData: null,
      authError: null,
      key_data: null, 
      child_keys: null,
      parent_keys: null,
      is_first_time_user: null,
      is_fetching_keys: true,
      agent:null
    };
  }

  componentDidMount() {
    // check the current user when the App component is loaded
    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.setState({ authState: 'signedIn', user: user });
        this.getAllKeys();
      })
      .catch((e) => {
        console.log(e);
        this.setState({ authState: 'signIn' });
      });
  }

  
  getAllKeys() {
    let apiName = 'botsheetsDashboardApi';
    let path = '/api';
    let myInit = {
      headers: {
        method: 'getKeys',
        sub: this.state.user.attributes.sub,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      },
      response: true, // (return the entire Axios response object instead of only response.data)
    };
    API.post(apiName, path, myInit)
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          let data = response.data.keys;
          var agent = data.filter((keyData) => !keyData.isChild)[0].agent
          agent = agent !== "null" ? agent : "manychat"
          agent = agent !== null ? agent : "manychat"
          console.log(agent)
          this.setState({ key_data: data, 
                          child_keys: data.filter((keyData) => keyData.isChild),
                          parent_keys: data.filter((keyData) => !keyData.isChild),
                          agent: agent,
                          is_first_time_user: false,
                          is_fetching_keys: false
                        });
          this.checkForStripeUser(data);
        } else {
          this.setState({ is_first_time_user: true,
                          is_fetching_keys: false,
                          agent:"manychat"
                        })
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  checkForStripeUser(data){
    let parent_keys = data.filter((keyData) => !keyData.isChild)
    let parent_key = parent_keys[0]
    if (parent_key['customerID'] == 'None' || parent_key['customerID'] =="" || !parent_key['customerID']){
      console.log("Creating Stripe customer")
      this.createStripeCustomer(parent_key.key)
    }
  }

  createStripeCustomer(key){
    let apiName = 'botsheetsDashboardApi';
    let path = '/api';
    let myInit = {
      headers: {
        method: 'createStripeCustomer',
        key: key,
        origin: window.location.href
      },
      response: true, // (return the entire Axios response object instead of only response.data)
    };
    API.post(apiName, path, myInit)
      .then((response) => {
        if (response.data.success) {
          let customer_id = response.data.id;
          let customer_email = response.data.email
          console.log(customer_id)
          //After creating the stripe customer, add the rewardful affiliate if there is one.
          console.log("running rewardful convert for " + customer_email)
          window.rewardful('convert', { email: customer_email })
          this.getAllKeys()
        } else {
          console.log(response.data)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getStripePortalURL(customer_id) {
    let apiName = 'botsheetsDashboardApi';
    let path = '/api';
    let myInit = {
      headers: {
        method: 'getStripeURL',
        customer_id: customer_id,
        return_url: 'https://app.botsheets.com',
        origin: window.location.href
      },
      response: true, // (return the entire Axios response object instead of only response.data)
    };
    return await API.post(apiName, path, myInit)
      .then((response) => {
        if (!response.data.stripe_data.error) {
          let stripe_url = response.data.stripe_data.url;
          console.log(stripe_url)
          return stripe_url
        } else {
          console.log(response.data.stripe_data.error)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async saveConfig(new_config, configID, configName = 'My Config') {
    let apiName = 'botsheetsDashboardApi';
    let path = '/api';
    let myInit = {
      headers: {
        method: 'saveConfig',
        'config-id': configID,
        'config-name': configName,
        sub: this.state.user.attributes.sub,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      },
      body: new_config,
      response: true, // (return the entire Axios response object instead of only response.data)
    };
    return await API.post(apiName, path, myInit)
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          console.log(response.data);
          return response
        } else {
          console.log('ERROR');
          return response
        }
      })
      .catch((error) => {
        console.log(error);
        return {'success':false}
      });
  }

  signOut() {
    Auth.signOut()
      .then(() => {
        this.setState({ authState: 'signIn' });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    const { authState } = this.state;
    return (
      <ThemeProvider theme={theme}>
        {authState === 'loading' && <LoadingScreen isLoading={authState === 'loading'}/>}
        {this.state.is_fetching_keys && <LoadingScreen isLoading={this.state.is_fetching_keys}/>}
        {authState === 'signIn' && <SignInScreen />}
        {authState === 'signedIn' && this.state.agent && (
          <Router>
            {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
            <Switch>
              <Route path="/webview">
                <WebviewsHome 
                  signOut={this.signOut} 
                  user={this.state.user} 
                  parentKeys= {this.state.parent_keys}
                  getStripePortalURL={this.getStripePortalURL}
                  agent={this.state.agent}
                  />
              </Route>
              <Route path="/query-builder">
                <QueryBuilder 
                  signOut={this.signOut} 
                  user={this.state.user} 
                  parentKeys= {this.state.parent_keys}
                  getStripePortalURL={this.getStripePortalURL}
                  agent={this.state.agent}
                  />
              </Route>
              <Route path="/toolkits">
                <Toolkits 
                  signOut={this.signOut} 
                  user={this.state.user}
                  parentKeys= {this.state.parent_keys}
                  getStripePortalURL={this.getStripePortalURL}
                  agent={this.state.agent}
                  />
              </Route>
              <Route path="/billing">
                <Billing 
                  signOut={this.signOut} 
                  parentKeys= {this.state.parent_keys}
                  user={this.state.user}
                  getStripePortalURL={this.getStripePortalURL}
                  />
              </Route>
              <Route path="/logs">
              {this.state.parent_keys == null ?
                (<div></div> )
                :
                (<LogsMain 
                signOut={this.signOut} 
                parentKeys= {this.state.parent_keys}
                user={this.state.user}
                getStripePortalURL={this.getStripePortalURL}
                agent={this.state.agent}
                />)
              }
              </Route>
              <Route path="/dev">
                <RewardfulDev 
                  signOut={this.signOut} 
                  parentKeys= {this.state.parent_keys}
                  user={this.state.user}
                  getStripePortalURL={this.getStripePortalURL}
                  />
              </Route>
              <Route path="/">
                <Dashboard 
                  signOut={this.signOut}
                  user={this.state.user}
                  getAllKeys={this.getAllKeys.bind(this)}
                  allKeys= {this.state.key_data} 
                  childKeys= {this.state.child_keys}
                  parentKeys= {this.state.parent_keys}
                  firstTimeUser= {this.state.is_first_time_user}
                  fetchingKeys= {this.state.is_fetching_keys}
                  getStripePortalURL={this.getStripePortalURL}
                  saveConfig={this.saveConfig.bind(this)}
                  agent={this.state.agent}
                  >
                </Dashboard>
              </Route>
            </Switch>
          </Router>
        )}
      </ThemeProvider>
    );
  }
}

export default App;
