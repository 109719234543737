import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';


export default function SelectionListContent(props) {
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    container: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
    addButton:{
      whiteSpace: 'nowrap',
      marginTop: theme.spacing(2)
    },
    howItWorks:{
      marginBottom: theme.spacing(2),
      textAlign:'right'
    }
  }));
  const classes = useStyles();

  useEffect(() => {
  }, []);
  
  function doOpenOldWebview(event) {
    event.preventDefault();
    props.setOpenOldWebview(true);
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="lg" className={classes.container}>
          <>
            <div className={classes.howItWorks}>
              <div >
                <Button variant="contained" color="primary" target="_blank" href="https://www.youtube.com/watch?v=Hrz1AskFFEk&ab_channel=Botsheets" >
                How it Works
                </Button>
              </div>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" color="primary">
                        Getting Started with Selection Lists
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <iframe 
                        width="560" 
                        height="315" 
                        src="https://www.youtube.com/embed/Hrz1AskFFEk" 
                        title="Get Started with Selection Lists" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen="true">
                    </iframe>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" color="primary">
                        Upgrading a Google Sheet to Support Selecton Lists
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <iframe 
                        width="560" 
                        height="315" 
                        src="https://www.youtube.com/embed/bXMa2xXjv8E" 
                        title="Upgrading a Google Sheet to Support Selecton Lists" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen="true">
                    </iframe>
                    </Grid>
                    <Grid item xs={12} style={{'paddingTop':'20px'}}>
                        <Typography variant="p" color="textPrimary">
                        If you're an existing Botsheets user and you've been using our Webview Builder to create Selection Lists,
                        we recommend updating our Manychat app to atleast version 25. You can update the app in ManyChat setting under the Apps 
                        section. This video shows you how to upgrade your Google Sheet template so that our ManyChat app can easily communicate with 
                        your existing Google Sheet and understand the new webview button type.{' '}
                            <Link href="#" color="primary" onClick={doOpenOldWebview}>
                            Still want to use the original Webview builder? Click here.
                            </Link>{' '}
                        </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </>
      </Container>
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  fixedHeight: {
    height: 240,
  },
}));
