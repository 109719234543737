import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import { Grid, Link } from '@material-ui/core';
import ParentKeyTableRow from './ParentKeyTableRow';
import AddPaidKeyModal from './Modals/AddPaidKeyModal';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';
import * as qs from 'query-string';

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  addButton: {
    whiteSpace: 'nowrap',
  },
  rowC: {
    display: 'flex',
    flexDirection: 'row',
  },
  table: {
    marginBottom: theme.spacing(3),
  },
}));

export default function ParentKeys(props) {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);

  const getAgentFromUrl = useCallback(() => {
    let _url_agent = qs.parse(window.location.search, { ignoreQueryPrefix: true })['agent'];
    let agent = _url_agent === undefined ? props.agent : _url_agent;
    return agent;
  });
  const agent = getAgentFromUrl();
  const [userAgent, setUserAgent] = React.useState(agent);

  useEffect(() => {
    const agent = getAgentFromUrl();
    setUserAgent(agent.toLowerCase());
  }, [getAgentFromUrl, props.agent, userAgent]);

  function openAddKeyModal() {
    setModalOpen(true);
  }

  function closeAddKeyModal() {
    setModalOpen(false);
  }

  return (
    <React.Fragment>
      <AddPaidKeyModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        closeAddKeyModal={closeAddKeyModal}
        oldKey={props.freeKey}
        user={props.user}
        getAllKeys={props.getAllKeys}
      ></AddPaidKeyModal>
      <div className={classes.rowC}>
        <Title>Botsheets Action Usage</Title>
      </div>
      <Grid item xs={12} style={{ paddingBottom: '10px' }}>
        <Typography color="textSecondary">
          One Botsheeets Action Key can be used on an unlimited number of chatbots. Usage and billing is tracked and managed separately from our  
          {' '}
          <Link href="https://chat.botsheets.com" target="_blank" color="primary">
            main Botsheets plans. See documentation for pricing tiers.{' '}
          </Link>{' '}
        </Typography>
      </Grid>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow style={{ backgroundColor: '#EFEFEF' }}>
            <TableCell>Plan</TableCell>
            <TableCell>Botsheets Key</TableCell>
            <TableCell align="right">Requests</TableCell>
            <TableCell>Upgrade</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.allKeys
            .filter((row) => !row.isChild)
            .map((keyData) => (
              <ParentKeyTableRow
                getAllKeys={props.getAllKeys}
                key={keyData.key}
                allKeys={keyData}
                getStripePortalURL={props.getStripePortalURL}
              />
            ))}
        </TableBody>
      </Table>
      {props.isFreeKey ? (
        <Typography color="textSecondary" align="left" style={{ fontWeight: 'bold' }}>
          If you upgrade to process more requests, you'll receive an email with a new Actions Key. You can then
          <Link href="#" onClick={openAddKeyModal} color="primary">
            {' '}
            add it here{' '}
          </Link>{' '}
          to switch your plan.
        </Typography>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {/*<Grid container alignItems="flex-start" justify="flex-start" direction="row">
        <Grid item className={classes.seeMore}>
          <NavLink to={`/Dashboard?agent=${agent}`} style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary" className={classes.addButton}>
              Set up Botsheets
            </Button>
          </NavLink>
        </Grid>
        <Grid item className={classes.seeMore} style={{ paddingLeft: 10 }}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.addButton}
            href="#"
            onClick={() =>
              window.open(
                `https://www.botsheets.com/${
                  agent === 'chatfuel' ? 'manychat' : agent
                }/how-it-works.html`,
                '_blank'
              )
            }
          >
            How it Works
          </Button>
        </Grid>
         <Grid item className={classes.seeMore} style={{ paddingLeft: 10 }}>
          <Button variant="contained" color="secondary" className={classes.addButton} href="#" onClick={() => window.open("https://www.botsheets.com/upgrade.php", "_blank")}>Compare Plans</Button>
        </Grid> 
      </Grid>*/}
      {/*
      <div className={classes.seeMore}>
        <Link color="primary" href="#" onClick={preventDefault}>
          <AddCircleOutlineIcon/>
        </Link>
      </div> */}
    </React.Fragment>
  );
}
