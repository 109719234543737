import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Grid } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { NavLink } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NoBotsheetsKeyModal(props) {
  return (
    <React.Fragment>
      <Dialog
        open={props.modalOpen}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid container>
          <Grid item xs={12} style={{ padding: 10 }}>
            <DialogTitle id="alert-dialog-slide-title">{'No Botsheets Key Found'}</DialogTitle>
            <DialogContent>
              <DialogContentText>You first need to add your Botsheets Key.</DialogContentText>
            </DialogContent>
          </Grid>
        </Grid>
        <DialogActions>
          <Link
            underline="none"
            component={({ className, children }) => (
              <NavLink className={className} to={`/`}>
                {children}
              </NavLink>
            )}
          >
            <Button color="primary">Add my Botsheets Key</Button>
          </Link>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
