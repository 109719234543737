import React, { useState } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { API } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Input from '@material-ui/core/Input';
import Link from '@material-ui/core/Link';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function GenerateChildKeyModal(props) {
  const [newKeyName, setNewKeyName] = useState('My Client');
  const [parentKey, setParentKey] = useState(
    props.keyData.filter((keyData) => !keyData.isChild)[0].key
  );
  const handleClose = () => {
    props.setGenerateKeyModalOpen(false);
  };

  function generateChildKey() {
    console.log(props.keyData.filter((keyData) => !keyData.isChild)[0].key);
    let apiName = 'botsheetsDashboardApi';
    let path = '/api';
    let myInit = {
      headers: {
        method: 'generateClientKey',
        key: parentKey,
        name: newKeyName,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      },
      response: true, // (return the entire Axios response object instead of only response.data)
    };
    API.post(apiName, path, myInit)
      .then((response) => {
        console.log(response);
        props.setGenerateKeyModalOpen(false);
        props.getAllKeys();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <React.Fragment>
      <Dialog
        open={props.generateKeyModalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {props.allowedToGenerate ? (
          <React.Fragment>
            <DialogTitle id="alert-dialog-slide-title">{'New Sub-Account'}</DialogTitle>
            <DialogContent>
              <DialogContentText>Enter a name for your Sub-Account.</DialogContentText>
              <Input
                id="name"
                type="text"
                onChange={(e) => setNewKeyName(e.target.value)}
                value={newKeyName}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={generateChildKey} color="primary">
                Create
              </Button>
            </DialogActions>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <DialogTitle id="alert-dialog-slide-title">{'Limit Reached'}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                You've reached your limit with the number of Sub-Accounts you can create. Upgrade
                your plan for more Sub-Accounts
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Link color="primary" href="https://app.botsheets.com/billing" target="_blank">
                <Button onClick={handleClose} color="primary">
                  Upgrade
                </Button>
              </Link>

              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  );
}
