import React, { useState } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { API } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Input from '@material-ui/core/Input';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddPaidKeyModal(props) {
  const [botsheetsKey, setBotsheetsKey] = useState('');
  const handleClose = () => {
    props.setModalOpen(false);
  };

  function switchKey() {
    let apiName = 'botsheetsDashboardApi';
    let path = '/api';
    let myInit = {
      headers: {
        method: 'switchKey',
        oldkey: props.oldKey,
        newkey: botsheetsKey,
        sub: props.user.attributes.sub,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      },
      response: true, // (return the entire Axios response object instead of only response.data)
    };
    API.post(apiName, path, myInit)
      .then((response) => {
        console.log(response);
        props.getAllKeys();
        props.closeAddKeyModal();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <React.Fragment>
      <Dialog
        open={props.modalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{'Replace Botsheets Key'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Paste in your Botsheets Action key.</DialogContentText>
          <Input
            fullWidth={true}
            id="name"
            type="text"
            onChange={(e) => setBotsheetsKey(e.target.value)}
            value={botsheetsKey}
          />
        </DialogContent>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Botsheet Actions are billed separately from our main Botsheets accounts. Billing is strictly in connection with your use of a Botsheets Action Key.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={switchKey} color="primary">
            Replace
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
