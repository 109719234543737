export default [
  'ARG',
  'BGN',
  'BMD',
  'BND',
  'BOB',
  'BRL',
  'BSD',
  'BWP',
  'BYN',
  'BZD',
  'CAD',
  'CFA',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CRC',
  'CUP',
  'CZK',
  'DKK',
  'DOP',
  'EGP',
  'EUR',
  'FJD',
  'FKP',
  'GBP',
  'GEL',
  'GGP',
  'GHS',
  'GIP',
  'GTQ',
  'GYD',
  'HKD',
  'HNL',
  'HRK',
  'HUF',
  'IDR',
  'ILS',
  'IMP',
  'INR',
  'IRR',
  'ISK',
  'JEP',
  'JMD',
  'JPY',
  'KGS',
  'KHR',
  'KPW',
  'KRW',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'MKD',
  'MNT',
  'MUR',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'SAR',
  'SBD',
  'SCR',
  'SEK',
  'SGD',
  'SHP',
  'SOS',
  'SRD',
  'SVC',
  'SYP',
  'THB',
  'TRY',
  'TTD',
  'TVD',
  'TWD',
  'UAH',
  'USD',
  'UYU',
  'UZS',
  'VEF',
  'VND',
  'XCD',
  'YER',
  'ZAR',
  'ZWD',
];
