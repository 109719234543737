import React, { useState } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { NavLink } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SetupModal(props) {
  const handleClose = () => {
    props.setShowSetupModal(false);
  };

  return (
    <div>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{ textAlign: 'center' }} id="alert-dialog-slide-title">{'Welcome to Botsheets!'}</DialogTitle>
        <div style={{'padding':30, textAlign: 'center'}} >
        <DialogContent>
          <DialogContentText style={{ textAlign: 'center' }} id="alert-dialog-slide-description">
             Access documentation to learn how to get started with Botsheets Actions for Manychat
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{justifyContent: 'center'}}>
          <NavLink to={`/dashboard`} style={{ textDecoration: 'none' }}>
            {/*<Button variant="contained" color="primary">
              Start Setup
            </Button>*/}
          </NavLink>
          <Button variant="contained" onClick={handleClose} color="secondary">
            Get Started
          </Button>
        </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
