import { Box, IconButton, makeStyles, Snackbar, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import MatColorField from '../../primitives/MatColorField';
import MatTextField from '../../primitives/MatTextField';
import { AddCircle as AddIcon, Close as CloseIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { FieldArray, Form, Formik, getIn, useFormikContext } from 'formik';
import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import currencies from './currencies';
import { isValidHexColor } from '../../primitives/ColorPicker';

function validate(values) {
  if (!isValidHexColor(values?.theme?.color?.main)) {
    return {
      theme: {
        color: {
          main: 'Invalid HEX value',
        },
      },
    };
  }
  for (const [i, collection] of Object.entries(values.collections)) {
    if (collection.select_type === 'checkbox' || collection.select_type === 'numeric') {
      const min = Number(collection.select_min);
      const max = Number(collection.select_max);

      let errors = null;

      if (isNaN(min) || min < 0 || Math.floor(min) !== min || min > max) {
        errors = { select_min: 'Invalid' };
      }
      if (isNaN(max) || max <= 0 || Math.floor(max) !== max || max < min) {
        errors = { ...(errors || {}), select_max: 'Invalid' };
      }
      if (errors) {
        return {
          collections: {
            //Should this be collections[i] = errors ?
            [i]: errors,
          },
        };
      }
    } else if (collection.select_type === 'radio') {
      if (!collection.select_default) {
        return {
          collections: {
            [i]: {
              select_default: 'Required',
            },
          },
        };
      }
    }

    for (const [j, column] of Object.entries(collection.columns)) {
      if (column.join_type === 'delimiter' && !column.join_with) {
        return {
          collections: {
            [i]: {
              columns: {
                [j]: {
                  join_with: 'Required',
                },
              },
            },
          },
        };
      }
    }
  }

  return {};
}

function GenericForm({ configID, configJson, saveConfig, classes, configMetadata, manychatFlows }) {
  const [isErrorSnackbarVisible, setIsErrorSnackbarVisible] = useState(false);
  const [isSaveSnackbarVisible, setIsSaveSnackbarVisible] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(true);

  async function onSubmit(new_config, { setSubmitting }) {
    setSubmitting(true);
    console.log(new_config);
    let configName = configMetadata['config-name'];
    await saveConfig(new_config, configID, configName).then((response) => {
      setSaveSuccess(response.data.success);
      setIsSaveSnackbarVisible(true);
    });

    setTimeout(() => {
      setSubmitting(false);
    });
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSaveSnackbarVisible(false);
  };

  const css = useCommonStyles();

  const initialValues = useMemo(
    // NOTE: when adding new fields to the form, add default values for it here.
    () => ({
      ...(configJson || {}),
      submit: {
        ...(configJson?.submit || {}),
        button: {
          ...(configJson?.submit?.button || {}),
          subtotal_display: configJson?.submit?.button?.subtotal_display || 'button',
        },
      },
    }),
    [configJson]
  );

  function format_flows(manychatFlows){
    let set = new Set();
    let formatted_flows = {};
    manychatFlows.filter(pageFlows => {
      let page_name = pageFlows.manychat_page
      pageFlows.flows.filter(page_flow => {
        let flow_id = page_flow.ns
        let flow_name = page_flow.name
        if (!set.has(flow_id)) {
          set.add(flow_id);
          formatted_flows[flow_id] = {"flow_names": page_name + ': ' + flow_name, "flow_id": flow_id}
        }else{
          formatted_flows[flow_id].flow_names = formatted_flows[flow_id].flow_names + ', ' + page_name + ': ' + flow_name
        }
      });
    });   
    console.log(Object.values(formatted_flows)) 
    return Object.values(formatted_flows)
  }

  return (
    <div style={{ minWidth: '100%', boxSizing: 'border-box' }}>
      <Box paddingTop="80px">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnChange={false}
          validateOnBlur
          validate={validate}
          validationSchema={Yup.object({
            title: Yup.string().required('Required'),
            currency: Yup.string().required('Required'),
            theme: Yup.object({
              color: Yup.object({
                main: Yup.string().required('Required'),
              }),
            }),
            collections: Yup.array().of(
              Yup.object({
                title: Yup.string().required('Required'),
                select_type: Yup.string().required(),
                columns: Yup.array().of(
                  Yup.object({
                    column_name: Yup.string().required('Required'),
                    mc_field: Yup.string().required('Required'),
                  })
                ),
              })
            ),
            submit: Yup.object({
              button: Yup.object({
                caption: Yup.string().required('Required'),
                target: Yup.string().required('Required'),
                subtotal_display: Yup.string().required('Required'),
              }),
            }),
          })}
        >
          {({
            values,
            dirty,
            isSubmitting,
            isSubmitted,
            handleSubmit,
            handleReset,
            isValid,
            validateForm,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box className={css.flexRow} style={{ flexShrink: 0 }} paddingLeft="16px">
                <Typography color="primary" variant="h6">
                  Main Settings
                </Typography>
              </Box>
              <Box className={css.flexRow} style={{ flexShrink: 0 }} padding="16px">
                <MatTextField name={`title`} label="Main Heading" />
                <MatColorField name={`theme.color.main`} label="Theme (HEX Value)" />
              </Box>
              <Box
                className={css.flexRow}
                style={{ flexShrink: 0 }}
                paddingLeft="16px"
                fontSize={20}
              >
                <Typography color="primary" variant="h6">
                  Selections
                </Typography>
              </Box>
              <FieldArray name="collections">
                {(collectionsArrayHelpers) => (
                  <>
                    <Box className={css.flexColumn}>
                      {values.collections.map((collection, i) => (
                        <CollectionEditor
                          key={i}
                          index={i}
                          path={`collections[${i}]`}
                          collection={collection}
                          arrayHelpers={collectionsArrayHelpers}
                          isLastItem={values.collections.length === 1}
                        />
                      ))}
                    </Box>
                    <Paper variant="outlined" style={{ margin: 16, borderColor: '#00a058' }}>
                      <Button
                        display="block"
                        style={{ width: '100%' }}
                        onClick={() => {
                          collectionsArrayHelpers.push({
                            title: '',
                            select_type: 'checkbox',
                            select_min: 0,
                            select_max: 999,
                            columns: [
                              {
                                column_name: '',
                                mc_field: '',
                                join_type: 'delimiter',
                                join_with: ', ',
                                type: 'string',
                              },
                            ],
                          });
                          setTimeout(validateForm);
                        }}
                      >
                        <Box display="flex" justifyContent="center" padding="16px">
                          <AddIcon color="primary" style={{ marginRight: 8 }} />
                          <div>Add Selection</div>
                        </Box>
                      </Button>
                    </Paper>
                  </>
                )}
              </FieldArray>
              <Box className={css.flexRow} style={{ flexShrink: 0 }} paddingLeft="16px">
                <Typography color="primary" variant="h6">
                  Submit Button
                </Typography>
              </Box>
              <Box className={css.flexRow} style={{ flexShrink: 0 }} padding="16px">
                <MatTextField name={`submit.button.caption`} label="Submit Button Label" />
                <MatTextField name={`submit.button.target`} label="Button Target" select >
                  {
                    format_flows(manychatFlows).map((pageFlow, i) => {
                    return <MenuItem value={pageFlow['flow_id']}>{pageFlow['flow_names']}</MenuItem>
                    })
                  }
                </MatTextField>

                <CurrencyField />
                <MatTextField
                  name={`submit.button.subtotal_display`}
                  select
                  label="Selection subtotal"
                >
                  <MenuItem value="hidden">Default button</MenuItem>
                  <MenuItem value="button">Show price on button</MenuItem>
                  <MenuItem value="above_button">Show price above button</MenuItem>
                </MatTextField>
              </Box>
              <Box display="flex" justifyContent="center" style={{ margin: 16 }}>
                <div
                  onClick={() => {
                    if (!isValid) {
                      setIsErrorSnackbarVisible(true);
                    }
                  }}
                  style={{ paddingRight: '20px' }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!dirty || isSubmitting}
                    size="large"
                    style={{ minWidth: '200px' }}
                  >
                    Save
                  </Button>
                </div>
                <Button
                  type="button"
                  variant="contained"
                  className="outline"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  Cancel
                </Button>
              </Box>
              {isErrorSnackbarVisible && !isValid && !isSubmitting ? (
                <Snackbar
                  open={isErrorSnackbarVisible}
                  autoHideDuration={6000}
                  onClose={() => setIsErrorSnackbarVisible(false)}
                >
                  <Alert onClose={() => setIsErrorSnackbarVisible(false)} severity="error">
                    Please fix all errors and try again.
                  </Alert>
                </Snackbar>
              ) : (
                ''
              )}
              <Snackbar open={isSaveSnackbarVisible} autoHideDuration={3000} onClose={handleClose}>
                {saveSuccess ? (
                  <Alert onClose={handleClose} severity="success">
                    Saved!
                  </Alert>
                ) : (
                  <Alert onClose={handleClose} severity="error">
                    Error on Saving
                  </Alert>
                )}
              </Snackbar>
              {/* <pre>{JSON.stringify({ errors, values }, null, 2)}</pre> */}
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
}

function CurrencyField() {
  const { values } = useFormikContext();
  const shouldShowCurrencyField = Boolean(
    values.collections.some((collection) =>
      collection.columns.some((column) => column.type === 'price')
    )
  );

  return shouldShowCurrencyField ? (
    <MatTextField name={`currency`} label="Currency" select>
      {currencies.map((currency) => (
        <MenuItem value={currency}>{currency}</MenuItem>
      ))}
    </MatTextField>
  ) : (
    <></>
  );
}

const BREAKPOINT = 848;

const useCommonStyles = makeStyles({
  flexRow: {
    display: 'flex',
    flexShrink: 0,
    '& > :not(:last-child)': {
      marginRight: 8,
    },
    '& > *': {
      minWidth: 'max-content',
    },
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    '& > :not(:last-child)': {
      marginBottom: 12,
    },
  },
  collectionCloseButton: {
    [`@media screen and (max-width: ${BREAKPOINT - 1}px)`]: {
      marginLeft: 'calc(100% - 56px)',
    },
    [`@media screen and (min-width: ${BREAKPOINT}px)`]: {
      position: 'absolute',
      top: 8,
      right: 8,
    },
  },
});

function CollectionEditor({ path, index, collection, arrayHelpers, isLastItem }) {
  const css = useCommonStyles();

  const { values, validateForm } = useFormikContext();
  const typeControls =
    getIn(values, `${path}.select_type`) === 'radio' ? (
      <MatTextField
        name={`${path}.select_default`}
        variant="outlined"
        label="Default Selection"
        style={{ width: 128 }}
        select
      >
        <MenuItem value="0">First</MenuItem>
        <MenuItem value="1">Second</MenuItem>
        <MenuItem value="2">Third</MenuItem>
        <MenuItem value="3">Fourth</MenuItem> {/*TODO Add a "Last" option */}
        <MenuItem value="4">Fifth</MenuItem> 
        <MenuItem value="5">Sixth</MenuItem>
        <MenuItem value="6">Seventh</MenuItem>
        <MenuItem value="7">Eighth</MenuItem>
        <MenuItem value="8">Ninth</MenuItem>
        <MenuItem value="9">Tenth</MenuItem>
      </MatTextField>
    ) : (
      <>
        <MatTextField
          name={`${path}.select_min`}
          label="Min Selection"
          select
          style={{ paddingRight: '16px' }}
        >
          <MenuItem value="0">Not Required</MenuItem>
          <MenuItem value="1">1</MenuItem>
          <MenuItem value="2">2</MenuItem>
          <MenuItem value="3">3</MenuItem>
          <MenuItem value="4">4</MenuItem>
          <MenuItem value="5">5</MenuItem>
          <MenuItem value="6">6</MenuItem>
          <MenuItem value="7">7</MenuItem>
          <MenuItem value="8">8</MenuItem>
          <MenuItem value="9">9</MenuItem>
          <MenuItem value="10">10</MenuItem>
        </MatTextField>
        <MatTextField name={`${path}.select_max`} label="Max Selection" style={{}} select>
          <MenuItem value="999">No Limit</MenuItem>
          <MenuItem value="1">1</MenuItem>
          <MenuItem value="2">2</MenuItem>
          <MenuItem value="3">3</MenuItem>
          <MenuItem value="4">4</MenuItem>
          <MenuItem value="5">5</MenuItem>
          <MenuItem value="6">6</MenuItem>
          <MenuItem value="7">7</MenuItem>
          <MenuItem value="8">8</MenuItem>
          <MenuItem value="9">9</MenuItem>
          <MenuItem value="10">10</MenuItem>
        </MatTextField>
      </>
    );

  return (
    <>
      <Paper
        variant="outlined"
        style={{
          borderColor: '#00a058',
          margin: 16,
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        {isLastItem ? (
          ''
        ) : (
          <IconButton
            className={css.collectionCloseButton}
            onClick={() => {
              arrayHelpers.remove(index);
              setTimeout(validateForm);
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
        <Box style={{ overflowX: 'auto' }}>
          <Box className={css.flexRow} style={{ flexShrink: 0 }} padding="16px">
            <MatTextField name={`${path}.title`} label="Selection Header" />
            <MatTextField name={`${path}.select_type`} label="Selection Type" select>
              {/*<MenuItem value="numeric">Numeric (multiple)</MenuItem>*/}
              <MenuItem value="checkbox">Checkboxes (multiple)</MenuItem>
              <MenuItem value="radio">Radio (single)</MenuItem>
            </MatTextField>
            {typeControls}
          </Box>
          <Box paddingLeft="16px">
            <Typography>Add Data from Google Sheets to Show in Webview</Typography>
          </Box>
          <Box padding="16px" paddingTop="0" className={css.flexRow} style={{ flexShrink: 0 }}>
            <FieldArray name={`${path}.columns`}>
              {(columnsArrayHelpers) => (
                <>
                  {collection.columns.map((column, i) => (
                    <ColumnEditor
                      key={i}
                      index={i}
                      path={`${path}.columns[${i}]`}
                      column={column}
                      arrayHelpers={columnsArrayHelpers}
                      isLastItem={collection.columns.length === 1}
                    ></ColumnEditor>
                  ))}
                  <Button
                    variant="outlined"
                    style={{
                      display: 'inline-flex',
                    }}
                    onClick={() => {
                      columnsArrayHelpers.push({
                        column_name: '',
                        mc_field: '',
                        join_type: 'delimiter',
                        join_with: ',',
                        type: 'string',
                      });
                      setTimeout(validateForm);
                    }}
                  >
                    <Box
                      alignItems="center"
                      justifyContent="center"
                      className={css.flexColumn}
                      padding="16px"
                    >
                      <AddIcon color="primary"></AddIcon>
                      <span>
                        Add <br />
                        column
                      </span>
                    </Box>
                  </Button>
                  <div style={{ paddingRight: 8 }}></div>
                </>
              )}
            </FieldArray>
          </Box>
        </Box>
      </Paper>
    </>
  );
}

function ColumnEditor({ path, index, column, arrayHelpers, isLastItem }) {
  const css = useCommonStyles();
  const { values, validateForm } = useFormikContext();

  const isDelimiterSelected = getIn(values, `${path}.join_type`) === 'delimiter';

  return (
    <Paper variant="outlined" style={{ width: 356 }}>
      <Box className={css.flexRow}>
        <Box display="flex" padding="16px" className={css.flexColumn}>
          <Box display="flex" className={css.flexRow}>
            <MatTextField name={`${path}.column_name`} label="Column Header Name"></MatTextField>
            <MatTextField name={`${path}.type`} label="Data Type" select style={{ width: 96 }}>
              <MenuItem value="string">Text</MenuItem>
              <MenuItem value="defaultNumber">Number</MenuItem>
              <MenuItem value="price">Price</MenuItem>
            </MatTextField>
          </Box>
          <Box display="flex" className={css.flexRow}>
            <MatTextField name={`${path}.mc_field`} label="Save to ManyChat User Field" />
            <MatTextField name={`${path}.join_type`} label="Save As" style={{ width: 140 }} select>
              <MenuItem value="delimiter">List</MenuItem>
              <MenuItem value="add">Total Amount</MenuItem>
            </MatTextField>
            {isDelimiterSelected ? (
              <MatTextField
                name={`${path}.join_with`}
                label="Separate with"
                style={{ maxWidth: 96 }}
                select
              >
                <MenuItem value=", ">,</MenuItem>
                <MenuItem value=" ">Space</MenuItem>
                <MenuItem value=" & ">&</MenuItem>
                <MenuItem value=" - ">-</MenuItem>
                <MenuItem value=" + ">+</MenuItem>
              </MatTextField>
            ) : (
              ''
            )}
          </Box>
        </Box>
        {isLastItem ? (
          ''
        ) : (
          <Box className={css.flexColumn} style={{ margin: '8px 8px 0 0' }}>
            <IconButton
              style={{ marginLeft: 'auto', height: '48px' }}
              onClick={() => {
                arrayHelpers.remove(index);
                setTimeout(validateForm);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </Paper>
  );
}

export default GenericForm;
