import React, { useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Switch from '@material-ui/core/Switch';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import EditIcon from '@material-ui/icons/Edit';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import KeyRenameModal from './Modals/KeyRenameModal';
import { API } from 'aws-amplify';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';

export default function ChildKeyTableRow(props) {
  let keyData = props.keyData;
  const [isActive, setIsActive] = useState(keyData.isActive);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  function preventDefault(event) {
    event.preventDefault();
  }

  const handleDeleteModalOpen = (event) => {
    event.preventDefault();
    setDeleteModalOpen(true);
  };

  function toggleIsActive() {
    console.log('Set ' + keyData.key + 'to: ' + !isActive);
    let apiName = 'botsheetsDashboardApi';
    let path = '/api';
    let myInit = {
      headers: {
        method: 'setClientKeyActive',
        key: keyData.key,
        active: '' + !isActive,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      },
      response: true, // (return the entire Axios response object instead of only response.data)
    };
    API.post(apiName, path, myInit)
      .then((response) => {
        console.log(response.data);
        setIsActive((prevIsActive) => !prevIsActive);
        props.getAllKeys();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /*function toggleShowKey(){
      setShowKey(prevShowKey => !prevShowKey)
    }*/

  const openModal = () => {
    setModalOpen(true);
  };

  return (
    <React.Fragment>
      <DeleteChildKeyModal
        keyData={keyData}
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        getAllKeys={props.getAllKeys}
      />
      <TableRow key={keyData.key}>
        <KeyRenameModal
          row={keyData}
          setModalOpen={setModalOpen}
          modalOpen={modalOpen}
          getAllKeys={props.getAllKeys}
        />
        <TableCell>
          {keyData.name}
          <Link color="primary" href="#" onClick={preventDefault}>
            <EditIcon onClick={openModal} fontSize="inherit" />
          </Link>
        </TableCell>
        <TableCell>
          {keyData.key}
          <Button
            style={{ 'paddingLeft': 0, 'paddingRight': 0 }}
            color="primary"
            href="#"
            onClick={preventDefault}
          >
            <FileCopyIcon
              fontSize="inherit"
              onClick={() => {
                navigator.clipboard.writeText(keyData.key);
              }}
            />
          </Button>
        </TableCell>
        <TableCell align="right">{`${keyData.requests}`}</TableCell>
        <TableCell>
          <Switch checked={isActive} onClick={toggleIsActive} color="primary" />
        </TableCell>
        <TableCell>
          <IconButton href="#" onClick={handleDeleteModalOpen}>
            <DeleteForeverOutlinedIcon color="primary"  />
          </IconButton>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DeleteChildKeyModal(props) {
  const handleClose = () => {
    props.setDeleteModalOpen(false);
  };
  function deleteChildKey() {
    console.log('DELETE: ' + props.keyData.key);
    let apiName = 'botsheetsDashboardApi';
    let path = '/api';
    let myInit = {
      headers: {
        method: 'deleteClientKey',
        key: props.keyData.key,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      },
      response: true, // (return the entire Axios response object instead of only response.data)
    };
    API.post(apiName, path, myInit)
      .then((response) => {
        console.log(response);
        props.setDeleteModalOpen(false);
        props.getAllKeys();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <React.Fragment>
      <Dialog
        open={props.deleteModalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {'Are you sure you want to delete this Sub-Account?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText color="primary" id={props.keyData.name}>
            {props.keyData.name}
          </DialogContentText>
          <DialogContentText color="primary" id={props.keyData.key}>
            {props.keyData.key}
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            WARNING: This key will be permanently deleted. Any bot using this key will stop
            communicating with Botsheets.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteChildKey} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
