import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BuildIcon from '@material-ui/icons/Build';
import SearchIcon from '@material-ui/icons/Search';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import Link from '@material-ui/core/Link';
import { NavLink } from 'react-router-dom';

export default function MainListItems(props) {
  return (
    <div>
      <Link
        underline="none"
        component={({ className, children }) => (
          <NavLink className={className} to={`/?agent=${props.userAgent}`} title={'Dashboard'}>
            {children}
          </NavLink>
        )}
      >
        <ListItem
          selected={props.isDisabled === 'Dashboard'}
          disabled={props.isDisabled === 'Dashboard'}
          button
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText
            primary="Dashboard"
          />
        </ListItem>
      </Link>
{/*
      <Link
        underline="none"
        component={({ className, children }) => (
          <NavLink className={className} to={`/toolkits?agent=${props.userAgent}`} title={`Toolkits`}>
            {children}
          </NavLink>
        )}
      >
        <ListItem
          button
          selected={props.isDisabled === 'Toolkits'}
          disabled={props.isDisabled === 'Toolkits'}
        >
          <ListItemIcon>
            <BuildIcon />
          </ListItemIcon>
          <ListItemText primary={props.userAgent === 'dialogflow' ? "Setup" : "Toolkits"} />
        </ListItem>
      </Link>
*/}
      {props.userAgent === 'manychat' &&
        <Link
          underline="none"
          component={({ className, children }) => (
            <NavLink className={className} to={`/webview?agent=${props.userAgent}`} title={`Selection Lists`}>
              {children}
            </NavLink>
          )}
        >
          <ListItem
            button
            selected={props.isDisabled === 'Webviews'}
            disabled={props.isDisabled === 'Webviews'}
          >
            <ListItemIcon>
              <ViewCompactIcon />
            </ListItemIcon>
            <ListItemText primary="Selection Lists" />
          </ListItem>
        </Link>
      }
      {/*
      <Link
        underline="none"
        component={({ className, children }) => (
          <NavLink className={className} to={`/query-builder?agent=${props.userAgent}`} title={`Query Builder`}>
            {children}
          </NavLink>
        )}
      >
        <ListItem
          button
          selected={props.isDisabled === 'Query Builder'}
          disabled={props.isDisabled === 'Query Builder'}
        >
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          <ListItemText primary="Query Builder" />
        </ListItem>
      </Link>
      */}
    </div>
  );
}
