/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  Drawer,
  AppBar,
  Box,
  Toolbar,
  Link,
  List,
  Grid,
  Typography,
  Divider,
  IconButton,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MainListItems from '../Dashboard/MainListItems';
import SecondaryListItems from '../Dashboard/SecondaryListItems';
import logo from '../images/botsheets-banner.png';
import { NavLink } from 'react-router-dom';
import AgentPicker from '../primitives/AgentPicker'
import * as qs from 'query-string';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  logo: {
    alignItems: 'center',
    padding: '8px',
    height: undefined,
    width: '100%', //"190px"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function QueryBuilder(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const getAgentFromUrl = () => {
    let _url_agent = qs.parse(window.location.search, { ignoreQueryPrefix: true })['agent']
    let agent = _url_agent === undefined ? props.agent : _url_agent
    return agent
  }
  const agent = getAgentFromUrl()
  const [userAgent, setUserAgent] = React.useState(agent);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const agent = getAgentFromUrl()
    setUserAgent(agent.toLowerCase())
  }, [getAgentFromUrl, props.agent, userAgent]);

  const getGoogleUrl = (channel) => {
    if (channel === 'dialogflow') {
      return 'https://www.botsheets.com/dialogflow/searching-data.html'
    } else if (channel === 'chatfuel') {
      return "https://www.botsheets.com/chatfuel/search-requests.html"
    } else {
      return "https://www.botsheets.com/manychat/search-requests.html"
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Query Builder
          </Typography>
          <Button onClick={props.signOut} color="secondary">
            Log out
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <Grid container>
          <Grid item xs={8}>
            <NavLink to={`/`}>
              <img src={logo} alt="Logo" className={classes.logo} />
            </NavLink>
          </Grid>
          <AgentPicker
            hidden={!open}
            setUserAgent={setUserAgent}
            userAgent={userAgent}
            parentKeys={props.parentKeys}
          />
          <Grid item xs={4}>
            <div className={classes.toolbarIcon}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
        <Divider />
        <List>
          <MainListItems userAgent={userAgent} isDisabled={'Query Builder'} />
        </List>
        <Divider />
        <List><SecondaryListItems
          userAgent={userAgent}
          parentKeys={props.parentKeys}
          getStripePortalURL={props.getStripePortalURL}
        /></List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Box padding="16px">
          <Typography>
            You can {' '}
            <Link href={getGoogleUrl(userAgent)} color="primary" target="_blank">
              search a Google Sheet
            </Link>{' '}
            using a variety of search criteria, retrieve data from your worksheets, and dynamically
            generate content for your bot. Use the query builder to more easily create search
            requests, then copy the query and paste it into a dynamic request URL.
          </Typography>
        </Box>
        <iframe
          src={`https://www.botsheets.com/query-builder?agent=${userAgent}`}
          width="100%"
          height="90%"
          display="initial"
          position="relative"
          frameBorder="0"
        />
      </main>
      <div className={classes.appBarSpacer} />
    </div>
  );
}
