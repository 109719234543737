import React from 'react';
import { useFormikContext, Field } from 'formik';
import { TextField } from '@material-ui/core';

export default function MatTextField({ name, label, children, ...props }) {
  const { validateForm } = useFormikContext();
  // NOTE: because of the way MUI is implemented, onChange doesn't
  // fire for <TextField select />.
  return (
    <Field validateOnBlur name={name}>
      {({ field, meta }) => (
        <TextField
          {...field}
          onChange={(...e) => {
            if (props.select) {
              setTimeout(validateForm);
            }
            field.onChange(...e);
          }}
          variant="outlined"
          error={Boolean(meta.error && meta.touched)}
          label={label}
          helperText={meta.error && meta.touched && meta.error}
          {...props}
        >
          {children}
        </TextField>
      )}
    </Field>
  );
}
