import React, { useEffect } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    }
}))

export default function LoadingScreen(props) {
    const classes = useStyles();

    return (
        <Backdrop className={classes.backdrop} open={props.isLoading}>
            <CircularProgress  />
        </Backdrop>
    )
}
