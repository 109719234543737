import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import axios from 'axios';
import EditIcon from '@material-ui/icons/Edit';
import GetConfigurationForm from './GetConfigurationForm';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ConfigRenameModal from './Modals/ConfigRenameModal';
import FileCopyIcon from '@material-ui/icons/FileCopy';

export default function ConfigurationRow(props) {
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [renameModalOpen, setRenameModalOpen] = React.useState(false);

  const handleEditOpen = (event) => {
    event.preventDefault();
    setEditOpen(true);
  };

  const handleClose = () => {
    setEditOpen(false);
  };

  const handleDeleteModalOpen = (event) => {
    event.preventDefault();
    setDeleteModalOpen(true);
  };

  const openRenameModal = () => {
    setRenameModalOpen(true);
  };

  function preventDefault(event) {
    event.preventDefault();
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    seeMore: {
      marginTop: theme.spacing(3),
    },
    title: {
      flexGrow: 1,
    },
  }));
  const classes = useStyles();

  return (
    <React.Fragment>
      <DeleteConfigurationModal
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        config={props.config}
        user={props.user}
        getConfigs={props.getConfigs}
      />
      <Dialog fullScreen open={editOpen} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Webview Configuration
            </Typography>
            <IconButton color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <GetConfigurationForm
          configID={props.config['config-id']}
          config={props.config}
          user={props.user}
          saveConfig={props.saveConfig}
          getConfig={props.getConfig}
          manychatFlows={props.manychatFlows}
        />
      </Dialog>
      <TableRow>
        <ConfigRenameModal
          setRenameModalOpen={setRenameModalOpen}
          modalOpen={renameModalOpen}
          config={props.config}
          getConfigs={props.getConfigs}
          user={props.user}
        />
        <TableCell align="left" padding="checkbox">
          <IconButton href="#" variant="outlined" onClick={handleEditOpen}>
            <EditIcon color="primary" />
          </IconButton>
        </TableCell>
        <TableCell align="left" padding="checkbox" style={{ whiteSpace: 'nowrap' }}>
            <Box style={{ paddingRight: '10px' }}>
              <Typography>
              {props.config['config-name'] + ' '}
              <Link color="primary" href="#" onClick={preventDefault}>
                <EditIcon onClick={openRenameModal} fontSize="inherit" />
              </Link>
              <Button
                style={{ 'paddingLeft': 0, 'paddingRight': 0 }}
                color="primary"
                href="#"
                onClick={preventDefault}
              >
              <FileCopyIcon
                fontSize="inherit"
                onClick={() => {
                  navigator.clipboard.writeText(props.config['config-name']);
                }}
              />
              </Button>
              </Typography>
            </Box>
        </TableCell>
        <TableCell align="right">
          <IconButton color="primary" href="#" onClick={handleDeleteModalOpen}>
            <DeleteForeverOutlinedIcon color="primary"  />
          </IconButton>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DeleteConfigurationModal(props) {
  const handleClose = () => {
    props.setDeleteModalOpen(false);
  };

  async function deleteConfiguration() {
    const headers = {
      headers: {
        method: 'deleteConfig',
        'config-id': props.config['config-id'],
        Accept: '*/*',
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'Access-Control-Allow-Credentials': 'true',
      },
    };
    const data = {};
    await axios
      .post('https://api.botsheets.com/dashboard/api', data, headers)
      .then((response) => {
        if (response.status == '200') {
          console.log(response);
          handleClose();
          props.getConfigs({ user: props.user });
          return { success: true };
        }
      })
      .catch((response) => {
        return { success: false, message: 'Error on deleting config' };
      });
  }

  return (
    <React.Fragment>
      <Dialog
        open={props.deleteModalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {'Are you sure you want to delete this Configuration?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText color="textSecondary" id={props.webviewKey}>
            Configuration Name:{' '}
          </DialogContentText>
          <Typography color="primary">{props.config['config-name']}</Typography>
        </DialogContent>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            WARNING: This Configuration will be permanently deleted. Any bot using this
            Configuration will stop communicating with Botsheets.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteConfiguration} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
