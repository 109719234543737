import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { API } from 'aws-amplify';

export default function ConfigRenameModal(props) {
  const [configName, setConfigName] = useState(props.config['config-name']);
  const closeModal = () => {
    props.setRenameModalOpen(false);
  };
  function handleSubmit(e) {
    e.preventDefault();
    console.log('Update DB with new key name: ' + configName);
    let apiName = 'botsheetsDashboardApi';
    let path = '/api';
    let myInit = {
      headers: {
        method: 'nameConfig',
        name: configName,
        'config-id': props.config['config-id'],
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      },
      response: true, // (return the entire Axios response object instead of only response.data)
    };
    API.post(apiName, path, myInit)
      .then((response) => {
        console.log(response);
        props.getConfigs({ user: props.user });
        closeModal();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Dialog open={props.modalOpen} onClose={closeModal} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit Configuration Name</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText>
            Give your Configuration a name. If you generate more Configurations, this will help to
            distinguish them.
          </DialogContentText>
          <Input
            id="name"
            type="text"
            onChange={(e) => setConfigName(e.target.value)}
            value={configName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Rename
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
