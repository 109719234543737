import React, { useState } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { API } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Input from '@material-ui/core/Input';
import BadKeyAlertModal from './Modals/BadKeyAlertModal';
import { Link } from '@material-ui/core';
import STARTER_KIT_PIZZA from '../WebviewBuilder/Configurations/Templates/starter_kit_pizza';
import STARTER_KIT_REAL_ESTATE from '../WebviewBuilder/Configurations/Templates/starter_kit_real_estate';
import { useHistory } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FirstTimeUserModal(props) {
  const [botsheetsKey, setBotsheetsKey] = useState('');
  const [showBadKeyTooltip, setShowBadKeyTooltip] = useState(false);
  const handleClose = () => {
    //props.setGenerateKeyModalOpen(false);
  };
  const history = useHistory();

  function registerKey() {
    let apiName = 'botsheetsDashboardApi';
    let path = '/api';
    let myInit = {
      headers: {
        method: 'registerKey',
        key: botsheetsKey,
        sub: props.user.attributes.sub,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      },
      response: true, // (return the entire Axios response object instead of only response.data)
    };
    API.post(apiName, path, myInit)
      .then((response) => {
        console.log(response);
        let data = response.data;
        props.saveConfig(STARTER_KIT_PIZZA, 'config_' + Number(Date.now()), "Starter Kit Pizza")
        props.saveConfig(STARTER_KIT_REAL_ESTATE, 'config_' + Number(Date.now()), "Starter Kit Real Estate")
        props.setFirstTimeUser(false);
        //props.setShowSetupModal(true);
        history.push(`/Dashboard`);
        props.getAllKeys();
      })
      .catch((error) => {
        console.log(error);
        setShowBadKeyTooltip(true);
      });
  }

  return (
    <React.Fragment>
      <Dialog
        open={props.firstTimeUser}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{'Add your Botsheets Key'}</DialogTitle>
        <DialogContent>
          <Input
            fullWidth={true}
            id="name"
            type="text"
            onChange={(e) => setBotsheetsKey(e.target.value)}
            value={botsheetsKey}
          />
        </DialogContent>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Add your Botsheets Action Key to access this portal. You can only get this key from our Messenger bot.
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            Need a Key? {' '}
            <Link color="primary" href="https://m.me/botsheets" target="_blank">
            {' '} Get one
            </Link>
            {' '} free.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=> window.open("https://m.me/botsheets", "_blank")} color="primary" variant="contained">
            Get a Key
          </Button>
          <Button onClick={registerKey} color="primary" variant="contained">
            Submit Key
          </Button>
        </DialogActions>
      </Dialog>
      {showBadKeyTooltip ? (
        <BadKeyAlertModal open={showBadKeyTooltip} setShowBadKeyTooltip={setShowBadKeyTooltip} />
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
}
