import React, { useState } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { API } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Input from '@material-ui/core/Input';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function VideoModal(props) {
  const handleClose = () => {
    props.setVideoModalOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={props.videoModalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{'How it Works'}</DialogTitle>
        <DialogContent style={{ overflow: "hidden"}}>
        <iframe 
        width="560" height="315" 
        src="https://www.youtube.com/embed/xU_1-5_25Wk" 
        frameBorder="0" 
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen></iframe>

        </DialogContent>

      </Dialog>
    </React.Fragment>
  );
}
