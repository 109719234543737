import React from 'react';
import { createUseStyles } from 'react-jss';
import { useEffect } from 'react';

const XY_SIZE = 10;

const card = {
  borderRadius: 2,
};

const control = {
  border: '1px solid black',
  boxShadow: '0 0 2px white',
  pointerEvents: 'none',
  position: 'absolute',
};

const useStyles = createUseStyles({
  root: {},
  colorSelectionRow: {
    marginTop: 8,
    display: 'flex',
    alignItems: 'center',
    '& > :not(:last-child)': {
      marginRight: 8,
    },
  },
  colorSelection: {
    ...card,
    height: 20,
    width: 20,
  },
  xy: {
    ...control,
    top: 0,
    left: 0,
    width: XY_SIZE,
    height: XY_SIZE,
    borderRadius: '50%',
  },
  z: {
    ...control,
    top: 0,
    left: -2,
    right: -2,
    background: 'transparent',
    height: XY_SIZE,
    borderRadius: 2,
  },
  colorControls: {
    display: 'flex',
  },
  slWrapper: {
    position: 'relative',
    marginRight: 8,
  },
  hWrapper: {
    position: 'relative',
  },
  slCanvas: { ...card, cursor: 'pointer' },
  hCanvas: { ...card, cursor: 'pointer' },
  input: {
    outline: 'none',
    padding: '4px',
    fontFamily: 'monospace',
    border: 'none',
    borderBottom: '1px solid gray',
    width: 96,
    borderRadius: 2,
  },
});

function useEventListener(object, eventName, callback, deps) {
  const listener = React.useCallback(callback, deps);

  React.useEffect(() => {
    if (!object) return;

    object.addEventListener(eventName, listener);
    return () => object.removeEventListener(eventName, listener);
  }, [eventName, object, listener]);
}

export default function ColorPicker({ color, onChange, emitChangeOnlyOnMouseUp = true }) {
  color = (color || '').trim();

  const svCanvasWidth = 200;
  const svCanvasHeight = 100;
  const hCanvasHeight = svCanvasHeight;

  const css = useStyles();
  const slCanvasRef = React.useRef(null);
  const hCanvasRef = React.useRef(null);
  const [[h, s, v], setHsv] = React.useState(hexToHsv(color || '#f00'));
  const [hMouseDown, setHMouseDown] = React.useState(false);
  const [svMouseDown, setSVMouseDown] = React.useState(false);

  const hexValue = rgbToHex(hsvToRgb([h, s, v]));
  const [r, g, b] = hsvToRgb([h, s, v]);
  const [gradientX, gradientY] = [s * svCanvasWidth, (1 - v) * svCanvasHeight];
  const gradientZ = h * hCanvasHeight;

  const setXY = React.useCallback(
    (x, y) => {
      x = clamp(x, 0, slCanvasRef.current.width);
      y = clamp(y, 0, slCanvasRef.current.height);

      setHsv([h, x / slCanvasRef.current.width, 1 - y / slCanvasRef.current.height]);
    },
    [setHsv, h]
  );

  useEffect(() => {
    console.log(color);
    const rgb = hexToRgb(color);
    if (rgb !== null) {
      const [h, s, v] = rgbToHsv(rgb);
      setHsv([h, s, v]);
    }
  }, [color]);

  useEffect(() => {
    if (onChange && !emitChangeOnlyOnMouseUp) {
      onChange(hexValue);
    }
  }, [emitChangeOnlyOnMouseUp, onChange, hexValue]);

  const setZ = React.useCallback(
    (z) => {
      z = clamp(z, 0, hCanvasRef.current.height);

      setHsv([z / hCanvasRef.current.height, s, v]);
    },
    [setHsv, s, v]
  );

  useEffect(() => {
    drawHGradient(hCanvasRef.current);
  }, []);

  useEffect(() => {
    drawSVGradient(slCanvasRef.current, h);
  }, [h]);

  useEventListener(
    window,
    'mouseup',
    () => {
      setSVMouseDown(false);
      setHMouseDown(false);
      if (onChange) {
        onChange(hexValue);
      }
    },
    [onChange, hexValue, setSVMouseDown, setHMouseDown]
  );

  useEventListener(
    window,
    'mousemove',
    (e) => {
      if (svMouseDown) {
        const mouseX = e.clientX;
        const mouseY = e.clientY;
        const canvasRect = slCanvasRef.current.getBoundingClientRect();
        setXY(mouseX - canvasRect.x, mouseY - canvasRect.y);
      } else if (hMouseDown) {
        const mouseY = e.clientY;
        const canvasRect = slCanvasRef.current.getBoundingClientRect();
        setZ(mouseY - canvasRect.y);
      }
    },
    [setXY, setZ, hMouseDown]
  );

  const onSVMouseDown = React.useCallback(
    (e) => {
      e.preventDefault();
      setSVMouseDown(true);
      setXY(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    },
    [setXY, setSVMouseDown]
  );

  const onHMouseDown = React.useCallback(
    (e) => {
      e.preventDefault();
      setHMouseDown(true);
      setZ(e.nativeEvent.offsetY);
    },
    [setZ, setHMouseDown]
  );

  return (
    <div className={css.root}>
      <div className={css.colorControls}>
        <div className={css.slWrapper}>
          <canvas
            className={css.slCanvas}
            ref={slCanvasRef}
            width={svCanvasWidth}
            height={svCanvasHeight}
            onMouseDown={onSVMouseDown}
          />
          <div
            className={css.xy}
            style={{
              background: `rgb(${r}, ${g}, ${b})`,
              transform: `translate(${gradientX - XY_SIZE / 2}px, ${gradientY - XY_SIZE / 2}px)`,
            }}
          />
        </div>
        <div className={css.hWrapper}>
          <canvas
            className={css.hCanvas}
            ref={hCanvasRef}
            height={svCanvasHeight}
            width="24"
            onMouseDown={onHMouseDown}
          />
          <div
            className={css.z}
            style={{
              background: `hsl(${h * 360}, 100%, 50%)`,
              transform: `translateY(${gradientZ - XY_SIZE / 2}px)`,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export function isValidHexColor(value) {
  if (!value) return false;
  return /#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/.test(value);
}

function drawSVGradient(canvas, h) {
  const ctx = canvas.getContext('2d');
  const gradB = ctx.createLinearGradient(0, 0, 0, canvas.height);
  gradB.addColorStop(0, 'white');
  gradB.addColorStop(1, 'black');
  const gradC = ctx.createLinearGradient(0, 0, canvas.width, 0);
  gradC.addColorStop(0, `hsla(${h * 360}, 100%, 50%, 0)`);
  gradC.addColorStop(1, `hsla(${h * 360}, 100%, 50%, 1)`);
  ctx.fillStyle = gradB;
  ctx.fillRect(0, 0, 255, 255);
  ctx.fillStyle = gradC;
  ctx.globalCompositeOperation = 'multiply';
  ctx.fillRect(0, 0, 255, 255);
  ctx.globalCompositeOperation = 'source-over';
}

function drawHGradient(canvas) {
  const ctx = canvas.getContext('2d');
  for (let y = 0; y < canvas.height; y++) {
    const h = y / canvas.height;
    const [r, g, b] = hsvToRgb([h, 1, 1]);
    ctx.fillStyle = `rgb(${r}, ${g}, ${b})`;
    ctx.fillRect(0, y, canvas.width, 2);
  }
}

function hsvToRgb([h, s, v]) {
  let r = 0,
    g = 0,
    b = 0;

  let i = Math.floor(h * 6);
  let f = h * 6 - i;
  let p = v * (1 - s);
  let q = v * (1 - f * s);
  let t = v * (1 - (1 - f) * s);

  switch (i % 6) {
    case 0:
      r = v;
      g = t;
      b = p;
      break;
    case 1:
      r = q;
      g = v;
      b = p;
      break;
    case 2:
      r = p;
      g = v;
      b = t;
      break;
    case 3:
      r = p;
      g = q;
      b = v;
      break;
    case 4:
      r = t;
      g = p;
      b = v;
      break;
    case 5:
    default:
      r = v;
      g = p;
      b = q;
      break;
  }

  return [Math.floor(r * 255), Math.floor(g * 255), Math.floor(b * 255)];
}

function rgbToHsv([r, g, b]) {
  r /= 255;
  g /= 255;
  b /= 255;

  let max = Math.max(r, g, b);
  let min = Math.min(r, g, b);
  let h = 0;
  let s;
  let v = max;

  let d = max - min;
  s = max === 0 ? 0 : d / max;

  if (max !== min) {
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return [h, s, v];
}

function hexToHsv(hex) {
  const rgb = hexToRgb(hex);
  if (rgb === null) return null;
  return rgbToHsv(rgb);
}

function toHex(value) {
  const hex = value.toString(16);
  return hex.length === 2 ? hex : `0${hex}`;
}

function hexToRgb(hex) {
  if (hex.length === 4) {
    return [
      parseInt(hex[1] + hex[1], 16),
      parseInt(hex[2] + hex[2], 16),
      parseInt(hex[3] + hex[3], 16),
    ];
  }
  if (hex.length === 7) {
    return [
      parseInt(hex.substring(1, 3), 16),
      parseInt(hex.substring(3, 5), 16),
      parseInt(hex.substring(5, 7), 16),
    ];
  }
  return null;
}

function rgbToHex([r, g, b]) {
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

function clamp(value, min, max) {
  if (value < min) return min;
  if (value > max) return max;
  return value;
}
