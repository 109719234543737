import React, { useState } from 'react';
import Link from '@material-ui/core/Link';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import PublishIcon from '@material-ui/icons/Publish';
import Button from '@material-ui/core/Button';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';

const usage_plans = {"Bot Sheets Pro":"Pro", "Bot Sheets Lite":"Lite", "Free-100":"Free", "Free-250":"Free"}

export default function ParentKeyTableRow(props) {
  const keyData = props.allKeys;
  //const [showKey, setShowKey] = useState(false)
  const [modalOpen, setModalOpen] = useState(false);

  function preventDefault(event) {
    event.preventDefault();
  }

  /*function toggleShowKey(){
      setShowKey(prevShowKey => !prevShowKey)
  }*/
  async function getStripeURL(event) {
    event.preventDefault();
    await props.getStripePortalURL(keyData.customerID).then((stripe_url)=>{
      console.log(stripe_url)
      window.open (stripe_url,'_self',false)
    })
  }
  return (
    <TableRow key={keyData.key}>
      <TableCell>{usage_plans[keyData.usagePlan] || keyData.usagePlan}</TableCell>
      <TableCell>
        {keyData.key}
        <Button
          style={{ 'paddingLeft': 0, 'paddingRight': 0 }}
          color="primary"
          href="#"
          onClick={preventDefault}
        >
          <FileCopyIcon
            fontSize="inherit"
            onClick={() => {
              navigator.clipboard.writeText(keyData.key);
            }}
          />
        </Button>
      </TableCell>
      <TableCell align="right">{`${keyData.requests} / ${keyData.limit}`}</TableCell>
      <TableCell>
        <IconButton color="primary" onClick={getStripeURL} target="_blank">
          <PublishIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
