export default {
  "submit": {
    "button": {
      "caption": "Add to Order",
      "target": "content20200606205331_440478"
    },
    "page": {
      "title": "Thank you",
      "url": "https://botsheets.com"
    }
  },
  "collections": [{
    "select_max": 3,
    "select_type": "radio",
    "title": "Choose a Size",
    "select_default": "0",
    "select_min": 1,
    "columns": [{
      "column_name": "Size",
      "join_type": "delimiter",
      "type": "string",
      "mc_field": "selected_size",
      "join_with": ", "
    }, {
      "column_name": "Price",
      "join_type": "add",
      "type": "price",
      "mc_field": "item_price",
      "join_with": ","
    }]
  }, {
    "select_max": 999,
    "select_type": "checkbox",
    "title": "Select Toppings \ud83c\udf55",
    "select_min": 0,
    "columns": [{
      "column_name": "Add ons",
      "join_type": "delimiter",
      "type": "string",
      "mc_field": "add-ons",
      "join_with": ", "
    }, {
      "column_name": "Price",
      "join_type": "add",
      "type": "price",
      "mc_field": "add-ons_price",
      "join_with": ","
    }]
  }],
  "currency": "USD",
  "theme": {
    "color": {
      "main": "#0078FF"
    }
  },
  "type": "generic",
  "title": "Customize Your Pizza",
  "version": "2"
}