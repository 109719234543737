import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  Grid,
  Typography,
  Divider,
  IconButton,
  Link,
  Box
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MainListItems from '../Dashboard/MainListItems';
import SecondaryListItems from '../Dashboard/SecondaryListItems';
import WebviewsContent from './WebviewsContent';
import SelectionListContent from './SelectionListContent';
import axios from 'axios';
import logo from '../images/botsheets-banner.png';
import { NavLink } from 'react-router-dom';
import AgentPicker from '../primitives/AgentPicker'
import * as qs from 'query-string';

//import {data} from './data/getKeys'
import { Auth } from 'aws-amplify';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  logo: {
    alignItems: 'center',
    padding: '8px',
    height: undefined,
    width: '100%', //"190px"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function getUserData() {
  Auth.currentAuthenticatedUser({
    bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  })
    .then((user) => console.log(user))
    .catch((err) => console.log(err));
}

export default function WebviewsHome(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [configs, setConfigs] = React.useState([]);
  const [userAgent, setUserAgent] = React.useState(props.agent);
  const [openOldWebview, setOpenOldWebview] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getUserData();
    let _url_agent = qs.parse(window.location.search, { ignoreQueryPrefix: true })['agent']
    let agent = _url_agent === undefined ? props.agent : _url_agent
    setUserAgent(agent.toLowerCase())
  }, [props.agent, userAgent]);

  async function getConfigs({ user, setFetchingConfigIDs }) {
    const headers = {
      headers: {
        method: 'getConfigs',
        sub: user.attributes.sub,
        Accept: '*/*',
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'Access-Control-Allow-Credentials': 'true',
      },
    };
    const data = {};
    await axios
      .post('https://api.botsheets.com/dashboard/api', data, headers)
      .then((response) => {
        if (response.status == '200') {
          setConfigs(response.data['configs'].map((config) => config));
          setFetchingConfigIDs(false);
          return { success: true };
        }
      })
      .catch((response) => {
        return { success: false, message: 'Error on getting config IDS' };
      });
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Selection Lists
          </Typography>
          <Button onClick={props.signOut} color="secondary">
            Log out
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <Grid container>
          <Grid item xs={8}>
            <NavLink to={`/`}>
              <img src={logo} alt="Logo" className={classes.logo} />
            </NavLink>
          </Grid>
          <AgentPicker
            hidden={!open}
            setUserAgent={setUserAgent}
            userAgent={userAgent}
            parentKeys={props.parentKeys}
          />
          <Grid item xs={4}>
            <div className={classes.toolbarIcon}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
        <Divider />
        <List>
          <MainListItems userAgent={userAgent} isDisabled={'Webviews'} />
        </List>
        <Divider />
        <List><SecondaryListItems
          userAgent={userAgent}
          parentKeys={props.parentKeys}
          getStripePortalURL={props.getStripePortalURL}
        /></List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {(userAgent === "chatfuel" || userAgent === "dialogflow") &&
          <div style={{ 'textAlign': 'center' }}>
            <h1 >
              <span aria-label="🔧" role='img'>Coming Soon! 🔧</span>
            </h1>
            <h3>
              Join the Botsheets community on Facebook to find out when
          </h3>
            <Button variant="contained" color="primary" target="_blank" href="https://www.facebook.com/groups/botsheets" >
              Join the Community
          </Button>
          </div>
        }
        {userAgent === "manychat" &&
          <div>
            <SelectionListContent
              setOpenOldWebview={setOpenOldWebview}
            />
            {openOldWebview &&
              <WebviewsContent
                user={props.user}
                getConfigs={getConfigs}
                configs={configs}
                setConfigs={setConfigs}
              />}
          </div>
        }
        <Box pt={4}>
          <Copyright />
        </Box>
      </main>
    </div>
  );
}


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://botsheets.com/">
        Botsheets
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}