import React from 'react';
import GoogleButton from 'react-google-button';
import { Auth } from 'aws-amplify';
import { withOAuth } from 'aws-amplify-react';

class SignInScreen extends React.Component {
  constructor(props) {
    super(props);
  }
  //Automatically start the Sign-in process. Don't use a sign-in button here
  signIn() {
    Auth.federatedSignIn({ provider: 'Google' });
  }
  render() {
    return (
      <div>
        <title></title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link href="./manage_files/bootstrap.css" rel="stylesheet" type="text/css" media="all" />
        <link
          href="./manage_files/stack-interface.css"
          rel="stylesheet"
          type="text/css"
          media="all"
        />
        <link href="./manage_files/theme.css" rel="stylesheet" type="text/css" media="all" />
        <link href="./manage_files/custom.css" rel="stylesheet" type="text/css" media="all" />
        <link href="./manage_files/css" rel="stylesheet" />

        <body data-smooth-scroll-offset="77">
          <div class="nav-container"> </div>
          <div class="main-container">
            <section class="imageblock switchable feature-large bg--primary space--md">
              <div class="imageblock__content col-md-6 col-sm-4 pos-right">
                <div
                  class="background-image-holder"
                  style={{
                    backgroundImage: `url(${'./manage_files/manage.png'})`,
                    opacity: '1',
                  }}
                >
                  {' '}
                  <img alt="image" src="./manage_files/manage.png" />{' '}
                </div>
              </div>
              <div class="container">
                <div class="row">
                  <div class="col-md-5 col-sm-7">
                    <h1>
                      <img
                        alt="image"
                        src="./manage_files/manage-logo.png"
                        class="logo image--xs"
                      />
                    </h1>
                    <h1>Botsheets Actions</h1>
                    <p class="lead">
                      A Manychat Google Sheets Integration for Messenger and Instagram DM.
                    </p>
                    <GoogleButton onClick={this.signIn} />
                    <span class="type--fine-print">
                      You'll need a{' '}
                      <a href="https://m.me/botsheets?ref=w9726491">Botsheets Action Key</a>. The
                      only way to get this Key is by chatting with our Messenger bot.
                    </span>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div class="all-page-modals"></div>
          <script src="./manage_files/jquery-3.1.1.min.js.download"></script>
          <script src="./manage_files/parallax.js.download"></script>
          <script src="./manage_files/smooth-scroll.min.js.download"></script>
          <script src="./manage_files/scripts.js.download"></script>
          <div
            class="container containerMeasure"
            style={{ opacity: '0', pointerEvents: 'none' }}
          ></div>
        </body>
      </div>
    );
  }
}

export default withOAuth(SignInScreen);
