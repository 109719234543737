import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { API } from 'aws-amplify';
import history from '../history'

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex'
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
    },
    hiddenClass:{
      display: "none"
    },
    visibleClass:{
      display: "inline"
    }
}));

export default function AgentPicker(props) {
    const [hidden, setHidden] = React.useState(props.hidden);
    const classes = useStyles();
    let pickerClass = hidden ? "hiddenClass" : "visibleClass"
    useEffect(() => {
      setHidden(props.hidden)
      if(props.parentKeys){
        updateAgent()
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.userAgent, props.hidden]);

    async function updateAgent() {
        var bsKey = props.parentKeys[0].key
        let apiName = 'botsheetsDashboardApi';
        let path = '/api';
        let myInit = {
          headers: {},
          body: {
              method:"updateAgent",
              'bs-key': bsKey,
              'agent': props.userAgent
          },
          response: true, // (return the entire Axios response object instead of only response.data)
        };
        return await API.post(apiName, path, myInit)
          .then((response) => {
            if (response.data.success) {
              return response
            } else {
              console.log('ERROR');
              return response
            }
          })
          .catch((error) => {
            console.log(error);
            return {'success':false}
          });
      }

    const handleAgentChange = (event) => {
        var new_agent = event.target.value
        props.setUserAgent(new_agent)
        history.push( window.location.pathname + '?agent='+new_agent); 
    };

    return (
        <Grid item xs={8} className={classes[pickerClass]} >
        <FormControl className={classes.formControl}>
        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
          Bot Builder:
        </InputLabel>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.userAgent === "" ? "manychat": props.userAgent}
            onChange={handleAgentChange}
        >
            <MenuItem value={"manychat"}>ManyChat</MenuItem>
            {/*
            <MenuItem value={"chatfuel"}>Chatfuel</MenuItem>
            <MenuItem value={"dialogflow"}>Dialogflow</MenuItem>
            */}
        </Select>
        </FormControl>
        </Grid>
    )
}