export default {
		"submit": {
			"button": {
				"subtotal_display": "hidden",
				"caption": "Set Shipping Country",
				"target": "content20200611194701_806219"
			},
			"page": {
				"title": "Thank you",
				"url": "https://botsheets.com"
			}
		},
		"collections": [{
			"select_max": 5,
			"select_default": "0",
			"columns": [{
				"column_name": "Country",
				"join_type": "delimiter",
				"type": "string",
				"mc_field": "shipping_country",
				"join_with": " "
			}, {
				"column_name": "Price",
				"join_type": "add",
				"type": "price",
				"mc_field": "shipping_cost",
				"join_with": ","
			}],
			"display": {
				"title": {
					"padding": "3",
					"size": "4",
					"color": "blue",
					"align": "center"
				},
				"columns": {
					"padding": "3",
					"size": "4",
					"color": "blue",
					"align": "center"
				}
			},
			"select_type": "radio",
			"title": "Select Your Country",
			"select_min": 0
		}],
		"currency": "USD",
		"theme": {
			"color": {
				"main": "#0078FF"
			}
		},
		"type": "generic",
		"title": "Shipping",
		"version": "2"
	}