import React, { useState } from 'react';
import {DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { Alert } from '@material-ui/lab';
import { API } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';
//import NotAllowedModal from '../../Modals/NotAllowedModal';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateWebviewKeyModal(props) {
  const [manychatAPIKey, setManychatAPIKey] = useState();
  const [botsheetsKey, setBotsheetsKey] = useState(props.botsheetsKeys[0].key);
  const [isSaveSnackbarVisible, setIsSaveSnackbarVisible] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  const handleClose = () => {
    props.setGenerateKeyModalOpen(false);
    setIsSaveSnackbarVisible(false)
  };
  
  const handleCloseSnackbar = () => {
    setIsSaveSnackbarVisible(false)
  };

  function createWebviewKey() {
    let apiName = 'botsheetsDashboardApi';
    let path = '/api';
    let myInit = {
      headers: {
        method: 'createWebviewKey',
        'bs-key': botsheetsKey,
        sub: props.user.attributes.sub,
        'mc-key': manychatAPIKey,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      },
      response: true, // (return the entire Axios response object instead of only response.data)
    };
    API.post(apiName, path, myInit)
      .then((response) => {
        console.log(response);
        setIsSaveSnackbarVisible(true)
        setSaveSuccess(true)
        props.setGenerateKeyModalOpen(false);
        //This will get webview Keys and manychat flows
        props.refreshData({
          user: props.user,
          setWebviewKeys: props.setWebviewKeys})
      })
      .catch((error) => {
        console.log(error.response.data.message);
        setIsSaveSnackbarVisible(true)
        setSaveSuccess(false)
        setErrorMessage(error.response.data.message)
      });
  }

  return (
    <React.Fragment>
        <Dialog
          open={props.generateKeyModalOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid container>
            <Grid item xs={12} style={{ padding: 10 }}>
              <DialogTitle id="alert-dialog-slide-title">{'Generate Webview Token'}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You'll need a Botsheets Key and a
                  <Tooltip
                    title={
                      <span style={{ fontSize: '12px' }}>
                        ManyChat > Settings > API > Get Token{' '}
                      </span>
                    }
                  >
                    <Link style={{ textDecoration: 'none' }}> ManyChat API Token </Link>
                  </Tooltip>
                  to create a Webview Token.
                </DialogContentText>
              </DialogContent>
              <DialogContent>
                <Input
                  id="name"
                  type="text"
                  fullWidth={true}
                  placeholder="Enter ManyChat API Token"
                  onChange={(e) => setManychatAPIKey(e.target.value)}
                  value={manychatAPIKey}
                />
              </DialogContent>
            </Grid>
            <Grid item xs={12} style={{ padding: 10 }}>
              <DialogContent>
                <InputLabel id="demo-simple-select-label">Botsheets Key</InputLabel>
                <Select
                  labelId="key-select-label"
                  id="key-select"
                  value={botsheetsKey}
                  onChange={(e) => {
                    setBotsheetsKey(e.target.value);
                  }}
                >
                  {props.botsheetsKeys.map((keyData) => {
                    return (
                      <MenuItem 
                      value={keyData.key}
                      key={keyData.key}>
                        {keyData.isChild ? keyData.name : 'Main Account'} - {keyData.key}
                      </MenuItem>
                    );
                  })}
                </Select>
              </DialogContent>
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={createWebviewKey} color="primary">
              Create
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar open={isSaveSnackbarVisible} autoHideDuration={10000} onClose={handleCloseSnackbar}>
          {saveSuccess ? (
            <Alert onClose={handleCloseSnackbar} severity="success">
              Created!
            </Alert>
          ) : (
            <Alert onClose={handleCloseSnackbar} severity="error">
              Error {errorMessage} Please make sure your bot is on a ManyChat Pro plan.
            </Alert>
          )}
        </Snackbar>
    </React.Fragment>
  );
}
