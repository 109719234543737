import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SecondaryListItems from './SecondaryListItems';
import MainListItems from './MainListItems';
import FirstTimeUserModal from './FirstTimeUserModal';
import SetupModal from './SetupModal';
import ParentKeys from './ParentKeys';
import ChildKeys from './ChildKeys';
import NoChildKeys from './NoChildKeys';
//import {data} from './data/getKeys'
import { API, Auth } from 'aws-amplify';
import logo from '../images/botsheets-banner.png';
import { NavLink } from 'react-router-dom';
import * as qs from 'query-string';
import AgentPicker from '../primitives/AgentPicker'
import LoadingScreen from '../primitives/LoadingScreen'

const subLimitDict = {
  Free: 0,
  'Free-100': 0,
  'Free-250': 0,
  'Bot Sheets Lite': 0,
  'Bot Sheets Pro': 5,
  'unlimited':10
};

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://botsheets.com/">
        Botsheets
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  logo: {
    alignItems: 'center',
    padding: '8px',
    height: undefined,
    width: '100%', //"190px"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
}));

function getUserData() {
  Auth.currentAuthenticatedUser({
    bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  })
    .then((user) => console.log(user))
    .catch((err) => console.log(err));
}

export default function Dashboard(props) {
  //const [childKeys, setChildKeys] = React.useState([]);
  //const [parentKeys, setParentKeys] = React.useState([]);
  const [allKeys, setAllKeys] = React.useState([]);
  const [firstTimeUser, setFirstTimeUser] = React.useState(props.firstTimeUser);
  const [showSetupModal, setShowSetupModal] = React.useState(false);
  //const [fetchingKeys, setFetchingKeys] = React.useState(true);
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [isFreeKey, setIsFreeKey] = React.useState();
  const [freeKey, setFreeKey] = React.useState();
  const [allowedToGenerateSubs, setAllowedToGenerateSubs] = React.useState(false);
  const [userAgent, setUserAgent] = React.useState(props.agent);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    console.log("Dashboard use effect")
    getUserData();
    if(props.allKeys && props.agent){
      checkForFreeKey(props.allKeys);
      checkIfSubsAllowed(props.allKeys)
      let _url_agent = qs.parse(window.location.search, { ignoreQueryPrefix: true })['agent']
      let agent = _url_agent === undefined ? props.agent: _url_agent
      console.log("Dashboard use effect setting user agent to " + agent)
      setUserAgent(agent.toLowerCase())
    }

  }, [props.allKeys, props.agent, userAgent]);


  //Check if any of the user's parent keys are a Free key. In the future the user should just have 1 parent key!
  function checkForFreeKey(data) {
    let freeKeys = data.filter((row) => !row.isChild && row.usagePlan.includes('Free'));
    if (freeKeys.length < 1) {
      setIsFreeKey(false);
    } else {
      setIsFreeKey(true);
      setFreeKey(freeKeys[0].key);
    }
  }

  function checkIfSubsAllowed(data) {
    let parent_key_data = data.filter((keyData) => !keyData.isChild)[0];
    let child_key_data = data.filter((keyData) => keyData.isChild);
    let sub_limit = 0;
    if (parent_key_data['subLimit']) {
      sub_limit = parent_key_data.subLimit;
    } else {
      sub_limit = subLimitDict[parent_key_data.usagePlan];
    }
    if (!sub_limit) {
      sub_limit = 0;
    }
    if (child_key_data.length >= sub_limit) {
      setAllowedToGenerateSubs(false);
    } else {
      setAllowedToGenerateSubs(true);
    }
  }

  return (
    <div className={classes.root}>
      {props.fetchingKeys ? (
        <LoadingScreen
        isLoading={props.fetchingKeys}
        />
      ) : (
        <div className={classes.root}>
        <CssBaseline />
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
            <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              Dashboard
            </Typography>
            <Button onClick={props.signOut} color="secondary">
              Log out
            </Button>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <Grid container>
            <Grid item xs={8}>
              <NavLink to={`/`}>
                <img src={logo} alt="Logo" className={classes.logo} />
              </NavLink>
            </Grid>
            <AgentPicker 
            hidden={!open}
            setUserAgent={setUserAgent} 
            userAgent={userAgent}
            parentKeys={props.parentKeys}/>
            <Grid item xs={4}>
              <div className={classes.toolbarIcon}>
                <IconButton onClick={handleDrawerClose}>
                  <ChevronLeftIcon />
                </IconButton>
              </div>
            </Grid>
          </Grid>
          <Divider />
          <List>
            <MainListItems userAgent={userAgent} isDisabled={'Dashboard'} />
          </List>
          <Divider />
          <List><SecondaryListItems 
            userAgent={userAgent}
            parentKeys={props.parentKeys}
            getStripePortalURL={props.getStripePortalURL}
            />
          </List>
        </Drawer>
        {props.firstTimeUser ? (
        <FirstTimeUserModal
            setUserAgent={setUserAgent} 
            userAgent={userAgent}
            user={props.user}
            firstTimeUser={props.firstTimeUser}
            setFirstTimeUser={setFirstTimeUser}
            setShowSetupModal={setShowSetupModal}
            getAllKeys={props.getAllKeys}
            saveConfig={props.saveConfig}
          />
        ) : (
        <div>
        {showSetupModal ? 
          (<SetupModal
            setShowSetupModal={setShowSetupModal}
          />):(<div></div>)}
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {props.parentKeys.length == 0 ? (
                  <React.Fragment></React.Fragment>
                ) : (
                  <Paper className={classes.paper}>
                    <ParentKeys
                      getAllKeys={props.getAllKeys}
                      allKeys={props.allKeys}
                      user={props.user}
                      freeKey={freeKey}
                      isFreeKey={isFreeKey}
                      getStripePortalURL={props.getStripePortalURL}
                      agent={props.agent}
                    />
                  </Paper>
                )}
              </Grid>
              {/* <Grid item xs={12}>
                <Paper className={classes.paper}>
                  {(() => {
                    switch (true) {
                      case props.parentKeys.length == 0:
                        return <React.Fragment></React.Fragment>;
                      case props.childKeys.length == 0:
                        return (
                          <NoChildKeys
                            allowedToGenerateSubs={allowedToGenerateSubs}
                            getAllKeys={props.getAllKeys}
                            allKeys={props.allKeys}
                            fetchingKeys={props.fetchingKeys}
                          />
                        );
                      case props.childKeys.length != 0:
                        return (
                          <ChildKeys
                            allowedToGenerateSubs={allowedToGenerateSubs}
                            getAllKeys={props.getAllKeys}
                            allKeys={props.allKeys}
                            user={props.user}
                          />
                        );
                      default:
                        return null;
                    }
                  })()}
                </Paper>
              </Grid> */}
            </Grid>
            <Box pt={4}>
              <Copyright /> 
            </Box>
          </Container>
        </main>
        </div>
        )}
        </div>
      )}
    </div>
  );
}
