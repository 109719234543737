import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  Grid,
  Typography,
  Divider,
  IconButton,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MainListItems from '../Dashboard/MainListItems';
import SecondaryListItems from '../Dashboard/SecondaryListItems';
import logo from '../images/botsheets-banner.png';
import { NavLink } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../Dashboard/Title';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import TimeAgo from 'react-timeago'
import englishStrings from 'react-timeago/lib/language-strings/en'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import {  API } from 'aws-amplify';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import RefreshIcon from '@material-ui/icons/Refresh';
import AgentPicker from '../primitives/AgentPicker'
import * as qs from 'query-string';

const formatter = buildFormatter(englishStrings)

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  logo: {
    alignItems: 'center',
    padding: '8px',
    height: undefined,
    width: '100%', //"190px"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflowY: 'scroll'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'scroll',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://botsheets.com/">
          Botsheets
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

function getLogs({setLogs, setFetchingLogs, parentKey}){
    let apiName = 'botsheetsDashboardApi';
    let path = '/logs?bsKey='+parentKey;
    let myInit = {
      headers: {
      },
      response: true, // (return the entire Axios response object instead of only response.data)
    };
    API.get(apiName, path, myInit)
      .then((response) => {
          console.log(response.data)
          setLogs(response.data)
          setFetchingLogs(false)
      })
      .catch((error) => {
        console.log(error);
        setFetchingLogs(false)
      });
  }

export default function LogsMain(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  //const logs = [{"message":"test","help":"help message", "timestamp":1602461355285}]
  const [logs, setLogs] = React.useState([]);
  const [fetchingLogs, setFetchingLogs] = React.useState(true);
  const [parentKey, setParentKeys] = React.useState(props.parentKeys[0].key);
  const [userAgent, setUserAgent] = React.useState(props.agent);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const refreshLogs = () => {
    setFetchingLogs(true)
    getLogs({setLogs,
    setFetchingLogs,
    parentKey})
  };

  useEffect(() => {
    console.log(parentKey)
    getLogs({
        setLogs,
        setFetchingLogs,
        parentKey
      })
    let _url_agent = qs.parse(window.location.search, { ignoreQueryPrefix: true })['agent']
    let agent = _url_agent === undefined ? props.agent: _url_agent
    setUserAgent(agent.toLowerCase())
  }, [parentKey, props.agent, userAgent]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Logs
          </Typography>
          <Button onClick={props.signOut} color="secondary">
            Log out
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <Grid container>
          <Grid item xs={8}>
            <NavLink to={`/`}>
              <img src={logo} alt="Logo" className={classes.logo} />
            </NavLink>
          </Grid>
          <AgentPicker 
          hidden={!open}
          setUserAgent={setUserAgent} 
          userAgent={userAgent}
          parentKeys={props.parentKeys}/>
          <Grid item xs={4}>
            <div className={classes.toolbarIcon}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
        <Divider />
        <List>
          <MainListItems userAgent={userAgent}/>
        </List>
        <Divider />
        <List><SecondaryListItems 
            userAgent={userAgent}
            isDisabled={'Logs'}
            parentKeys={props.parentKeys}
            getStripePortalURL={props.getStripePortalURL}
            /></List>
      </Drawer>
      <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
              <Paper className={classes.paper}>
              <div style={{display:"flex", justifyContent:"space-between" }}>
              <Title>Botsheets Error Events</Title>
              {fetchingLogs ? <React.Fragment/> : 
              <Link href="#" onClick={refreshLogs}>
              <RefreshIcon color="primary"/>
              </Link>
              }
              </div>
                  {fetchingLogs ? 
                  (<CircularProgress />) 
                  :
                  (
                    logs.length == 0 ? (
                        <React.Fragment>Everything looks good. Are you experiencing issues? You can talk to our {' '}
                        <Link color="primary" href="https://m.me/botsheets?ref=w11862664" target="_blank">
                        {' '} Bot
                        </Link> for support.</React.Fragment>
                      ) : (
                          <React.Fragment>
                          <Table size="small">
                            <TableHead>
                              <TableRow style={{backgroundColor:'#EFEFEF'}}>
                                <TableCell>Error Type</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Date</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {logs.map((log,i) => (
                                <TableRow key={i}>
                                  <TableCell><Typography><Box fontWeight="fontWeightMedium">{log.message}</Box></Typography></TableCell>
                                  <TableCell><Typography><Box fontWeight="fontWeightMedium">{`${log.help}`}</Box></Typography></TableCell>
                                  <TableCell><Typography><Box fontWeight="fontWeightMedium"><TimeAgo date={log.timestamp} formatter={formatter} /></Box></Typography></TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </React.Fragment>
                      )
                  )
                  }
                </Paper>
              </Grid>
            </Grid>
            <Box pt={4}>
              <Copyright /> 
            </Box>
          </Container>
        </main>
    </div>
  );
}
