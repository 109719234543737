// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id: 'us-east-1:92129705-dec5-4135-ba35-17e2bc507cdf',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_APQKWRpcY',
  aws_user_pools_web_client_id: '6o6buu2821i4dqngmefpg2s6lo',
  oauth: {
    domain: 'botsheets-dashboard-botsheets.auth.us-east-1.amazoncognito.com',
    scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    //redirectSignIn: 'http://localhost:3000',
    //redirectSignOut: 'http://localhost:3000',
    redirectSignIn: 'https://app.botsheets.com',
    redirectSignOut: 'https://app.botsheets.com',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_dynamodb_all_tables_region: 'us-east-1',
  aws_dynamodb_table_schemas: [
    {
      tableName: 'dynamo822ad5cd-botsheets',
      region: 'us-east-1',
    },
  ],
};

export default awsmobile;
