import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PaymentIcon from '@material-ui/icons/Payment';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import WarningIcon from '@material-ui/icons/Warning';
import Link from '@material-ui/core/Link';
import { NavLink } from 'react-router-dom';

export default function SecondaryListItems(props) {
  const getVideoUrl = (channel) => {
    if (channel === 'dialogflow') {
      return 'https://youtu.be/JodzACryn4A'
    } else if (channel === 'chatfuel') {
      return "https://www.youtube.com/channel/UC--4pnpLfjabnqi1wDSmtuw/featured"
    } else {
      return "https://www.youtube.com/playlist?list=PLbqqvBuc7fb844txZTenD_WQmy4TYkkFo"
    }
  }


  return (
    <div>
      <ListSubheader inset></ListSubheader>

      <Link
        underline="none"
        component={({ className, children }) => (
          <NavLink className={className} to={`/billing`} >
            {children}
          </NavLink>
        )}
      >
        <ListItem
          button
          selected={props.isDisabled === 'Billing'}
          disabled={props.isDisabled === 'Billing'}
        >
          <ListItemIcon>
            <PaymentIcon />
          </ListItemIcon>
          <ListItemText primary="Billing" />
        </ListItem>
      </Link>
{/*
      <Link
        underline="none"
        href={getVideoUrl(props.userAgent)}
        target="_blank"
      >
        <ListItem button>
          <ListItemIcon>
            <VideoLibraryIcon />
          </ListItemIcon>
          <ListItemText primary="Videos" />
        </ListItem>
      </Link>
*/}
      <Link
        underline="none"
        href={`https://support.botsheets.com/docs/mc-getstarted`}
        target="_blank"
      >
        <ListItem button>
          <ListItemIcon>
            <ImportContactsIcon />
          </ListItemIcon>
          <ListItemText primary="Documentation" />
        </ListItem>
      </Link>

      <Link underline="none" href="https://support.botsheets.com" target="_blank">
        <ListItem button>
          <ListItemIcon>
            <HelpOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Support" />
        </ListItem>
      </Link>

      {/* <Link underline="none" href="https://botsheets.com/affiliate" target="_blank">
        <ListItem button>
          <ListItemIcon>
            <MonetizationOnIcon />
          </ListItemIcon>
          <ListItemText primary="Affiliates" />
        </ListItem>
      </Link> */}

      <Link
        underline="none"
        component={({ className, children }) => (
          <NavLink className={className} to={`/logs?agent=${props.userAgent}`} title={`Logs`}>
            {children}
          </NavLink>
        )}
      >
        <ListItem
          button
          selected={props.isDisabled === 'Logs'}
          disabled={props.isDisabled === 'Logs'}
        >
          <ListItemIcon>
            <WarningIcon />
          </ListItemIcon>
          <ListItemText primary="Logs" />
        </ListItem>
      </Link>

    </div>
  );
}
