export default {
  "submit": {
    "button": {
      "caption": "Add to Order",
      "target": "content20200606205331_440478"
    },
    "page": {
      "title": "Thank you",
      "url": "https://botsheets.com"
    }
  },
  "collections": [{
    "select_max": "999",
    "select_default": 0,
    "columns": [{
      "column_name": "Add ons",
      "join_type": "delimiter",
      "type": "string",
      "mc_field": "add-ons",
      "join_with": ", "
    }],
    "display": {
      "title": {
        "padding": "3",
        "size": "4",
        "color": "blue",
        "align": "center"
      },
      "columns": {
        "padding": "3",
        "size": "4",
        "color": "blue",
        "align": "center"
      }
    },
    "select_type": "checkbox",
    "title": "Select Add-ons \ud83e\udd6a",
    "select_min": 0
  }],
  "currency": "USD",
  "theme": {
    "color": {
      "main": "#0078FF"
    }
  },
  "type": "generic",
  "title": "Customize Your Sandwich",
  "version": "2"
}